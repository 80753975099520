import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import WOW from "wowjs";

import HomeArcitech from "../../../assets/images/Homearcitech.png";
import HomearcitechLogo from "../../../assets/images/HomearcitechLogo.png";
import HomearcitechLogoHover from "../../../assets/images/HomearcitechLogoHover.png";

export default function App() {


  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  return (

    <section className="HomeHovers py-5" id="HomeArchitechture">
      <div className="container">
        <div className="row flex-direction-mobile">
          <div className="col-md-5 position-relative">
            <div className="ContentSection">
              <a href="/architectures">
                <h6>EXPLORE MORE</h6>
                <h2 className="mb-3">
                  architecture
                </h2>
                <p>
                  Building better structures, sustainability, and designs with an expressive identity, liberating them from the conventional ideas of construction and engineering.
                </p>
              </a>
            </div>
          </div>
          <div className="col-md-7">
            <div className="HomeContentImg">
              <img alt="HomearcitechLogoHover" src={HomearcitechLogoHover} className="homelogoHover " />
              <img alt="HomearcitechLogo" src={HomearcitechLogo} className="homelogo " />
              <img alt="HomeArcitech" src={HomeArcitech} className="homeImg" />
            </div>
          </div>
        </div>
      </div>
    </section>


  );
}
