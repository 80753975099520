import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#000',
    padding: '25px 0',
    position: 'relative',
    zIndex: '1',
    minHeight: '100%',
    [theme.breakpoints.down("xs")]: {
      padding: '0',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    justifyItems: 'center',
    marginTop: 30,
  },
  follow: {
    width: '110px',
    [theme.breakpoints.down("xs")]: {
      width: '130px',
    },
  },

  inforList: {
    display: 'flex',
    justifyContent: 'center',
    listStyle: 'none',
    padding: '0',
    marginBottom: '7px',

    '& li': {
      [theme.breakpoints.down("xs")]: {
        padding: '5px 0',
      },
    },

    [theme.breakpoints.down("xs")]: {
      display: 'flex',
      flexDirection: 'column',
      justifContent: 'center',
      textAlign: 'center',
    },
  },

  subText: {
    color: '#fff',
    padding: '0px 14px',
    fontSize: '19px',
    textDecoration: 'none',

    [theme.breakpoints.down("xs")]: {
      fontSize: '22px',
    },

    '&:hover': {
      color: "#0cafff",
    },
  }, 
  footerLogo: {
    width: '80%',
    paddingTop: '15px',
	  paddingLeft: '25px',
    [theme.breakpoints.down("xs")]: {
       padding: '10px 0 0 0',
       width: '80%'
    },

  },
  logoSection:{
    textAlign: 'center'
  },
  subtextGrid:{
    paddingTop: '1rem',
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: '0.25rem'
   },
  },
  CopyRight: {
    fontSize: '12px',
    color: '#fff',
    fontWeight: '400',
    textAlign: 'center',
  },
  Follow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '5px',
    marginBottom: '7px',

    [theme.breakpoints.between("xs","sm")]: {
      marginTop: '10px',
    },
  },
  FollowSpan: {
    color: '#fff',
    fontSize: '28px',
    fontWeight: '600',
    [theme.breakpoints.down("xs")]: {
      fontSize: '32px',
    },
  },
  SocialUls: {
    display: 'flex',
    justifyContent: 'center',
    listStyle: 'none',
    marginBottom: '5px',
    paddingLeft: '20px',

    [theme.breakpoints.down("xs")]: {
      paddingLeft: '0px'
    },
    '& img': {
      width: '40px',
      height: '24px',
      verticalAlign: 'bottom',
      '&:last-child': {
        display: 'none',
      },
    },

    '& a': {
      display: 'block',
      '&:hover': {
        '& img': {
          '&:last-child': {
            display: 'block',
          },
          '&:first-child': {
            display: 'none',
          },
        },
      },
    }

  }


}));
