import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  withWidth,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { styles as useStyles } from './styles';
import InnerBg from '../../assets/images/COALB.gif';
import MenuIcons from '../../assets/images/menu.svg';

const Navbar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
 /* const [workClick, ] = useState(false);

useEffect(()=>{
  console.log({workClick});
  if(workClick){
    
    window.scrollTo(0,600);
  }
},{workClick})*/

  const openNav = () => {
    setOpen(true);
    document.getElementById("mySidenav").style.width = "320px";
  }
  const closeNav = () => {
    setOpen(false);
    document.getElementById("mySidenav").style.width = "0px";
  }
  const renderDesktopMenu = () => {
    return (
      <>
        {open === false &&
          <IconButton
            onClick={openNav}
            edge="start"
            color="default"
            aria-label="main nav"
            className='MenuIcon'
          >
            <img src={MenuIcons} alt="MenuIcon"/>
          </IconButton>}

        <div className={classes.menu} id="mySidenav">
          <IconButton
            onClick={closeNav}
            edge="start"
            color="default"
            aria-label="main nav"
            className='Close'
          >
            x
          </IconButton>
          <div className='SideUls'>
		        <a href='/' className="menuOption">Home</a>
            
			<a href='/work/' className="menuOption">Work</a>
            <a href='/about/' className="menuOption">About</a>
            <a href='/press/' className="menuOption">Press</a>
            <a href='/contact/' className="menuOption">Contact</a>
          </div>

          <div className='socialUls'>
            <ul >
              <li><a rel="noreferrer" href='https://www.instagram.com/colab.co/' target='_blank'>Instagram</a></li>
              <li><a rel="noreferrer" href='https://www.facebook.com/ColabCompany/' target='_blank'>Facebook</a></li>
              <li><a rel="noreferrer" href='https://www.linkedin.com/company/colab-company/' target='_blank'>Linkedin</a></li>
              <li><a rel="noreferrer" href='https://www.youtube.com/@ColabCompany/featured' target='_blank'>Youtube</a></li>
            </ul>
            <p>© 2023 by ColabCompany. all rights reserved.</p>
          </div>
        </div>
      </>
    );
  };
  console.log({location:window.location})
  
  return (
    <AppBar className={classes.mainAppbar}>
      <Toolbar className={classes.toolbar}>
      {open && (window.location.pathname==='/' || window.location.pathname==='/colab') && <img src={InnerBg} alt="Logo" className={classes.innerBg} />}
        {renderDesktopMenu()}
      </Toolbar>
    </AppBar>
  );
};
export default withWidth()(Navbar);
