import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import WOW from "wowjs";

import HomeFurniture from "../../../assets/images/Homefurniture.png";
import HomeFurnitureLogo from "../../../assets/images/HomefurnitureLogo.png";
import HomeFurnitureLogoHover from "../../../assets/images/HomefurnitureLogoHover.png";

export default function App() {


  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  return (
      <section id="HomeFurniture" className="HomeHovers py-5">
        <div className="container">
          <div className="row flex-direction-mobile">
            <div className="col-md-5 position-relative">
              <div className="ContentSection">
                <a href="/furnitures">
                  <h6>EXPLORE MORE</h6>
                  <h2 className="mb-3">
                    FURNITURE
                  </h2>
                  <p>
                    Our avowed idea is to create furniture that is a beautification element in itself, pragmatic, and tailored to each individual’s idea of comfort and luxury.
                  </p>
                </a>
              </div>
            </div>
            <div className="col-md-7">
              <div className="HomeContentImg">
                <img alt="HomeFurnitureLogo" src={HomeFurnitureLogoHover} className="homelogoHover " />
                <img alt="HomeFurniture" src={HomeFurnitureLogo} className="homelogo " />
                <img alt="HomeFurnitur" src={HomeFurniture} className="homeImg" />
              </div>
            </div>
          </div>
        </div>
      </section>


  );
}
