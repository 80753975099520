import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import InnerBg from '../../assets/images/contact.gif';
import Swal from 'sweetalert2';
import emailjs from 'emailjs-com';
import { styles as useStyles } from './styles';

const Contact = () => {
const SERVICE_ID = "service_bur1qss";
const TEMPLATE_ID = "template_avjyc7k";
const USER_ID = "WxXPK8gweoe7CwPz9";


  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        Swal.fire({
          icon: 'success',
          title: 'Message Sent Successfully'
        })
      }, (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Ooops, something went wrong',
          text: error.text,
        })
      });
    e.target.reset()
  };
  //const mailchimpTx = require("@mailchimp/mailchimp_transactional")("md-fiGrwqboxNR4iTiBvvoqvw");
  const defaultValues = { name: '', email: '', reason: '', phone: '', message: '', errors: { email: '' } }
  const [form, setForm] = useState(defaultValues);
  const classes = useStyles();

  const changeHandler = (fieldName, event) => {
    const value = event.target.value;
    switch (fieldName) {
      case 'name':
        setForm({ ...form, name: value });
        break;
      case 'email':
        isEmailValid(value)
          ? setForm({ ...form, email: value, errors: { ...form.errors, email: '' } })
          : setForm({ ...form, errors: { ...form.errors, email: 'Please enter valid email' } });
        break;
      case 'reason':
        setForm({ ...form, reason: value, errors: { ...form.errors, email: '' } })
        break;
      case 'phone':
        isPhoneValid(value)
          ? setForm({ ...form, phone: value, errors: { ...form.errors, phone: '' } })
          : setForm({ ...form, errors: { ...form.errors, phone: 'Please enter valid phone number' } });
        break;
      case 'message':
        setForm({ ...form, message: value })
        break;
      default:
        setForm(defaultValues);
    }
  }
  const isEmailValid = (email) => {
    const emailRegexp = new RegExp(
    /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i
     )
    return emailRegexp.test(email)
  }
  const isPhoneValid = (phone) => {
    const phoneRegexp = /^[6-9]\d{9}$/gi;
    return phoneRegexp.test(phone);
  }

  return (
    <div className={classes.main}>
      <Navbar />
      <a href='/' className="InnerBg contact">
        <img src={InnerBg} alt="" />
      </a>
      <div className="container pb-3">
        <div className="row align-items-center">
          <div className="col-md-6 contact">
            <h1 className='heading headingAbout mb-4 pb-2'>
              Contact
            </h1>
            <p className='mb-4'>
              101, FLOOR 1, Bhakti Park Building,RHB Road, <br />
              Mulund West,  Mumbai 400080.
            </p>
            <p>
              E: <a href="mailto:colabcompany@gmail.com">  colabcompany@gmail.com</a> <br />
              T: <a href="tel:+91-9821092109">  +91- 9821092109</a>
            </p>
          </div>
          <div className="col-md-6 contact-form">
            <p>We create spaces that have remarkable design sensibility and regard environmental sustainability
              using the most innovative technologies and materials that reflect a strong emotional vibrance.</p>
            <form className="mt-4" onSubmit={handleOnSubmit}>
              <div className="mb-2">
                <input type="text" name='user_name' className="form-control" placeholder="Name*" onChange={(event) => changeHandler('name', event)} />
              </div>
              <div className="mb-2">
                <input type="text"  name='user_email' className="form-control" placeholder="E mail ID*" onChange={(event) => changeHandler('email', event)} />
                <p className={classes.error}>{form.errors.email}</p>
              </div>
              <div className="mb-2">
                <select className="form-control" onChange={(event) => changeHandler('reason', event)}>
                  <option>Reason for contact</option>
                  <option name='user_reason' value={'I would like to know about something'}>I would like to know about something</option>
                  <option name='user_reason' value={'I have a business enquiry for Colab Company'}>I have a business enquiry for Colab Company</option>
                  <option name='user_reason' value={'I have a press enquiry for Colab Company'}>I have a press enquiry for Colab Company</option>
                  <option name='user_reason' value={'I got a project to discuss with Colab Company'}>I got a project to discuss with Colab Company</option>
                </select>
              </div>
              <div className="mb-2">
                <input name='user_phone' type="text" className="form-control" placeholder="Phone" onChange={(event) => changeHandler('phone', event)} />
                <p className={classes.error}>{form.errors.phone}</p>
              </div>
              <div className="mb-2">
                <textarea name='user_message' className="form-control" placeholder="Message" onChange={(event) => changeHandler('message', event)}></textarea>
              </div>
              <div className="mb-2 text-center text-md-start">
                <button className="btn-black" disabled={!form.name || !form.email}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
