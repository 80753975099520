//correct data
import Interiors1 from "../assets/images/interior/Project1.jpg";
import Interiors2 from "../assets/images/interior/Project2.jpg";
import Interiors3 from "../assets/images/interior/Project3.jpg";
import Interiors4 from "../assets/images/interior/Project4.jpg";
import Interiors5 from "../assets/images/interior/Project5.jpg";
import Interiors6 from "../assets/images/interior/Project6.jpg";
import Interiors7 from "../assets/images/interior/Project7.jpg";
import Interiors8 from "../assets/images/interior/Project8.jpg";
import Interiors9 from "../assets/images/interior/Project9.jpg";
import Interiors10 from "../assets/images/interior/Project10.jpg";
import Interiors11 from "../assets/images/interior/Project11.jpg";
import Interiors12 from "../assets/images/interior/Project12.jpg";
import Interiors13 from "../assets/images/interior/Project13.jpg";
import Interiors14 from "../assets/images/interior/Project14.jpg";
import Interiors15 from "../assets/images/interior/Project15.jpg";
import Interiors16 from "../assets/images/interior/Project16.jpg";
import Interiors17 from "../assets/images/interior/Project17.jpg";
import Interiors18 from "../assets/images/interior/Project18.jpg";
import Interiors19 from "../assets/images/interior/Project19.jpg";
import Interiors20 from "../assets/images/interior/teavillacorp.png";
import February30 from "../assets/images/interior/february30.png";

import Interiors21 from "../assets/images/interior/Interiors21.png";
import Interiors22 from "../assets/images/interior/Interiors22.png";
import Interiors23 from "../assets/images/interior/Interiors23.png";
//import Interiors24 from "../assets/images/interior/Interiors24.png";
import Interiors25 from "../assets/images/interior/Interiors25.png";
import Interiors26 from "../assets/images/interior/CASA-NEUE.png";
import Interiors27 from "../assets/images/interior/ORANGATUN-ESPORTS.png";
import Interiors29 from "../assets/images/interior/kunenema-modern.png";
import Interiors30 from "../assets/images/interior/main-image_abode.jpg";

import DIGIENTCHAPTER1 from "../assets/images/interior/DIGIENTCHAPTER1.jpg";
import DIGIENTCHAPTER2 from "../assets/images/interior/DIGIENTCHAPTER2.jpg";
import DIGIENTCHAPTER3 from "../assets/images/interior/DIGIENTCHAPTER3.jpg";
import DIGIENTCHAPTER4 from "../assets/images/interior/DIGIENTCHAPTER4.jpg";
import DIGIENTCHAPTERBEG from "../assets/images/interior/interior33.png";



/* videoIcon */
import CH1 from "../assets/images/interior-detail/CH1.svg";
import CH2 from "../assets/images/interior-detail/CH2.svg";
import CH3 from "../assets/images/interior-detail/CH3.svg";
import CH4 from "../assets/images/interior-detail/CH4.svg";
import Beirut from '../assets/images/interior-detail/watch.png';
import Winevilla from '../assets/images/interior-detail/winevilla.svg';
import Avantgarde from '../assets/images/interior-detail/avantgarde.svg';
import Casaneue from '../assets/images/interior-detail/casaneue.svg';
import Hex from '../assets/images/interior-detail/hex.svg';
import Orangutan from '../assets/images/interior-detail/orangutan.svg';
import Goldenlotto from '../assets/images/interior-detail/goldenlotto.svg';
import Feb30 from '../assets/images/interior-detail/feb30.svg';


/* videoIcon */

/* interior1*/
import Slider1 from "../assets/images/interior-detail/interior1/banner/winevilla01.jpg";
import Slider2 from "../assets/images/interior-detail/interior1/banner/winevilla02.jpg";
import Slider3 from "../assets/images/interior-detail/interior1/banner/winevilla03.jpg";
import Slider4 from "../assets/images/interior-detail/interior1/banner/winevilla04.jpg";
import Slider5 from "../assets/images/interior-detail/interior1/banner/winevilla05.jpg";

import Section1 from "../assets/images/interior-detail/interior1/section/winevilla06.jpg";
import Section2 from "../assets/images/interior-detail/interior1/section/winevilla07.jpg";
import Section3 from "../assets/images/interior-detail/interior1/section/winevilla08.jpg";

import Video1 from "../assets/images/interior-detail/interior1/video/winevilla_video.mov";
import C1 from "../assets/images/interior-detail/interior1/winevilla_C.png";
/* interior1*/

/* interior2*/
import Slider6 from "../assets/images/interior-detail/interior2/banner/BEIRUT01.png";
import Slider7 from "../assets/images/interior-detail/interior2/banner/BEIRUT02.png";
import Slider8 from "../assets/images/interior-detail/interior2/banner/BEIRUT03.png";
import Slider9 from "../assets/images/interior-detail/interior2/banner/BEIRUT04.png";
import Slider10 from "../assets/images/interior-detail/interior2/banner/BEIRUT05.png";
import BEIRUT06 from "../assets/images/interior-detail/interior2/banner/BEIRUT06.png";

import Section4 from "../assets/images/interior-detail/interior2/section/Beirut09.png";
import Section5 from "../assets/images/interior-detail/interior2/section/beirut10.png";
import Section6 from "../assets/images/interior-detail/interior2/section/beirut11.png";

import C2 from "../assets/images/interior-detail/interior2/Beirut_C.png";
/* interior2*/

/* interior3*/
import Slider11 from "../assets/images/interior-detail/interior3/banner/HEX01.png";
import Slider12 from "../assets/images/interior-detail/interior3/banner/HEX02.png";
import Slider13 from "../assets/images/interior-detail/interior3/banner/HEX03.png";
import Slider14 from "../assets/images/interior-detail/interior3/banner/HEX04.png";
import Slider15 from "../assets/images/interior-detail/interior3/banner/HEX05.png";
import HEX06 from "../assets/images/interior-detail/interior3/banner/HEX06.png";
import HEX07 from "../assets/images/interior-detail/interior3/banner/HEX07.png";
import HEX08 from "../assets/images/interior-detail/interior3/banner/HEX08.png";

import Section7 from "../assets/images/interior-detail/interior3/section/HEX09.png";
import Section8 from "../assets/images/interior-detail/interior3/section/HEX10.png";
import Section9 from "../assets/images/interior-detail/interior3/section/HEX11.png";

import Video3 from "../assets/images/interior-detail/interior3/video/HEX.mov";
import C3 from "../assets/images/interior-detail/interior3/HEX_C.png";
/* interior3*/

/* interior4*/
import Slider16 from "../assets/images/interior-detail/interior4/banner/BCB01.png";
import Slider17 from "../assets/images/interior-detail/interior4/banner/BCB02.png";
import Slider18 from "../assets/images/interior-detail/interior4/banner/BCB03.png";
import Slider19 from "../assets/images/interior-detail/interior4/banner/BCB04.png";
import Slider20 from "../assets/images/interior-detail/interior4/banner/BCB05.png";
import BCB06 from "../assets/images/interior-detail/interior4/banner/BCB06.png";
import BCB07 from "../assets/images/interior-detail/interior4/banner/BCB07.png";

import Section10 from "../assets/images/interior-detail/interior4/section/BCB08.png";
import Section11 from "../assets/images/interior-detail/interior4/section/BCB09.png";
import Section12 from "../assets/images/interior-detail/interior4/section/BCB10.png";

import C4 from "../assets/images/interior-detail/interior4/BCB_C.png";
/* interior4*/

/* interior5*/
import Slider21 from "../assets/images/interior-detail/interior5/banner/MASTER01.png";
import Slider22 from "../assets/images/interior-detail/interior5/banner/MASTER02.png";
import Slider23 from "../assets/images/interior-detail/interior5/banner/MASTER03.png";
import Slider24 from "../assets/images/interior-detail/interior5/banner/MASTER04.png";
import Slider25 from "../assets/images/interior-detail/interior5/banner/MASTER05.png"; 
import MASTER06 from "../assets/images/interior-detail/interior5/banner/MASTER06.png";

import Section13 from "../assets/images/interior-detail/interior5/section/MASTER07.png";
import Section14 from "../assets/images/interior-detail/interior5/section/MASTER08.png";
import Section15 from "../assets/images/interior-detail/interior5/section/MASTER09.png";

import C5 from "../assets/images/interior-detail/interior5/MASTER_C.png";
/* interior5*/

/* interior6*/
import Slider26 from "../assets/images/interior-detail/interior6/banner/FISCO01.png";
import Slider27 from "../assets/images/interior-detail/interior6/banner/FISCO02.png";
import Slider28 from "../assets/images/interior-detail/interior6/banner/FISCO03.png";
import Slider29 from "../assets/images/interior-detail/interior6/banner/FISCO04.png";
import Slider30 from "../assets/images/interior-detail/interior6/banner/FISCO05.png";
import FISCO06 from "../assets/images/interior-detail/interior6/banner/FISCO06.png";
import FISCO07 from "../assets/images/interior-detail/interior6/banner/FISCO07.png";
import FISCO08 from "../assets/images/interior-detail/interior6/banner/FISCO08.png";

import Section16 from "../assets/images/interior-detail/interior6/section/FISCO09.png";
import Section17 from "../assets/images/interior-detail/interior6/section/FISCO10.png";
import Section18 from "../assets/images/interior-detail/interior6/section/FISCO11.png";

import C6 from "../assets/images/interior-detail/interior6/FISCO_C.png";
/* interior6*/

/* interior7*/
import Slider31 from "../assets/images/interior-detail/interior7/banner/TUNAK01.png";
import Slider32 from "../assets/images/interior-detail/interior7/banner/TUNAK02.png";
import Slider33 from "../assets/images/interior-detail/interior7/banner/TUNAK03.png";
import Slider34 from "../assets/images/interior-detail/interior7/banner/TUNAK04.png";
import Slider35 from "../assets/images/interior-detail/interior7/banner/TUNAK05.png";
import TUNAK06 from "../assets/images/interior-detail/interior7/banner/TUNAK06.png";
import TUNAK07 from "../assets/images/interior-detail/interior7/banner/TUNAK07.png";
import TUNAK08 from "../assets/images/interior-detail/interior7/banner/TUNAK08.png";

import Section19 from "../assets/images/interior-detail/interior7/section/TUNAK09.png";
import Section20 from "../assets/images/interior-detail/interior7/section/TUNAK10.png";
import Section21 from "../assets/images/interior-detail/interior7/section/TUNAK11.png";

import C7 from "../assets/images/interior-detail/interior7/TUNAK_C.png";
/* interior7*/

/* interior8*/
import Slider36 from "../assets/images/interior-detail/interior8/banner/SHALOM01.png";
import Slider37 from "../assets/images/interior-detail/interior8/banner/SHALOM02.png";
import Slider38 from "../assets/images/interior-detail/interior8/banner/SHALOM03.png";
import Slider39 from "../assets/images/interior-detail/interior8/banner/SHALOM04.png";
import Slider40 from "../assets/images/interior-detail/interior8/banner/SHALOM05.png"; 
import SHALOM06 from "../assets/images/interior-detail/interior8/banner/SHALOM06.png";
import SHALOM07 from "../assets/images/interior-detail/interior8/banner/SHALOM07.png";
import SHALOM08 from "../assets/images/interior-detail/interior8/banner/SHALOM08.png";

import Section22 from "../assets/images/interior-detail/interior8/section/SHALOM09.png";
import Section23 from "../assets/images/interior-detail/interior8/section/SHALOM10.png";
import Section24 from "../assets/images/interior-detail/interior8/section/SHALOM11.png";

import C8 from "../assets/images/interior-detail/interior8/SHALOM_C.png";
/* interior8*/

/* interior9*/
import Slider41 from "../assets/images/interior-detail/interior9/banner/bombay01.png";
import Slider42 from "../assets/images/interior-detail/interior9/banner/bombay02.png";
import Slider43 from "../assets/images/interior-detail/interior9/banner/bombay03.png";
import Slider44 from "../assets/images/interior-detail/interior9/banner/bombay04.png";
import Slider45 from "../assets/images/interior-detail/interior9/banner/bombay05.png"; 
import bombay06 from "../assets/images/interior-detail/interior9/banner/bombay06.png";
import bombay07 from "../assets/images/interior-detail/interior9/banner/bombay07.png";
import bombay08 from "../assets/images/interior-detail/interior9/banner/bombay08.png";

import Section25 from "../assets/images/interior-detail/interior9/section/bombay09.png";
import Section26 from "../assets/images/interior-detail/interior9/section/bombay10.png";
import Section27 from "../assets/images/interior-detail/interior9/section/bombay11.png";

import C9 from "../assets/images/interior-detail/interior9/bombay.png";
/* interior9*/

/* interior10*/
import Slider46 from "../assets/images/interior-detail/interior10/banner/savage01.png";
import Slider47 from "../assets/images/interior-detail/interior10/banner/savage02.png";
import Slider48 from "../assets/images/interior-detail/interior10/banner/savage03.png";
import Slider49 from "../assets/images/interior-detail/interior10/banner/savage04.png";
import Slider50 from "../assets/images/interior-detail/interior10/banner/savage05.png";
 import savage06 from "../assets/images/interior-detail/interior10/banner/savage06.png";
import savage07 from "../assets/images/interior-detail/interior10/banner/savage07.png";
import savage08 from "../assets/images/interior-detail/interior10/banner/savage08.png";

import Section28 from "../assets/images/interior-detail/interior10/section/savage09.png";
import Section29 from "../assets/images/interior-detail/interior10/section/savage10.png";
import Section30 from "../assets/images/interior-detail/interior10/section/savage11.png";

import C10 from "../assets/images/interior-detail/interior10/Savage_C.png";
/* interior10*/

/* interior11*/
import Slider51 from "../assets/images/interior-detail/interior11/banner/Teavilla01.png";
import Slider52 from "../assets/images/interior-detail/interior11/banner/Teavilla02.png";
import Slider53 from "../assets/images/interior-detail/interior11/banner/Teavilla03.png";
import Slider54 from "../assets/images/interior-detail/interior11/banner/Teavilla04.png";
import Slider55 from "../assets/images/interior-detail/interior11/banner/Teavilla05.png"; 
import Teavilla06 from "../assets/images/interior-detail/interior11/banner/Teavilla06.png";
import Teavilla07 from "../assets/images/interior-detail/interior11/banner/Teavilla07.png";
import Teavilla08 from "../assets/images/interior-detail/interior11/banner/Teavilla08.png";

import Section31 from "../assets/images/interior-detail/interior11/section/Teavilla09.png";
import Section32 from "../assets/images/interior-detail/interior11/section/Teavilla10.png";
import Section33 from "../assets/images/interior-detail/interior11/section/Teavilla11.png";

import C11 from "../assets/images/interior-detail/interior11/TEAVILLA_C.png";
/* interior11*/

/* interior12*/
import Slider56 from "../assets/images/interior-detail/interior12/banner/BLANCO01.png";
import Slider57 from "../assets/images/interior-detail/interior12/banner/BLANCO02.png";
import Slider58 from "../assets/images/interior-detail/interior12/banner/BLANCO03.png";
import Slider59 from "../assets/images/interior-detail/interior12/banner/BLANCO04.png";
import Slider60 from "../assets/images/interior-detail/interior12/banner/BLANCO05.png"; 
import BLANCO06 from "../assets/images/interior-detail/interior12/banner/BLANCO06.png";
import BLANCO07 from "../assets/images/interior-detail/interior12/banner/BLANCO07.png";
import BLANCO08 from "../assets/images/interior-detail/interior12/banner/BLANCO08.png";

import Section34 from "../assets/images/interior-detail/interior12/section/BLANCO09.png";
import Section35 from "../assets/images/interior-detail/interior12/section/BLANCO10.png";
import Section36 from "../assets/images/interior-detail/interior12/section/BLANCO11.png";

import C12 from "../assets/images/interior-detail/interior12/BLANCO_C.png";
/* interior12*/

/* interior13*/
import Slider61 from "../assets/images/interior-detail/interior13/banner/Teavilla01.png";
import Slider62 from "../assets/images/interior-detail/interior13/banner/Teavilla02.png";
import Slider63 from "../assets/images/interior-detail/interior13/banner/Teavilla03.png";
import Slider64 from "../assets/images/interior-detail/interior13/banner/Teavilla04.png";
import Slider65 from "../assets/images/interior-detail/interior13/banner/Teavilla05.png";
import Teavilla1306 from "../assets/images/interior-detail/interior13/banner/Teavilla06.png";
import Teavilla1307 from "../assets/images/interior-detail/interior13/banner/Teavilla07.png";

import Section37 from "../assets/images/interior-detail/interior13/section/Teavilla08.png";
import Section38 from "../assets/images/interior-detail/interior13/section/Teavilla09.png";
import Section39 from "../assets/images/interior-detail/interior13/section/Teavilla10.png";

import C13 from "../assets/images/interior-detail/interior13/teavilla-Indore_C.png";
/* interior13*/

/* interior14*/
import Slider66 from "../assets/images/interior-detail/interior14/banner/BLANCOBANDRA01.png";

import Slider68 from "../assets/images/interior-detail/interior14/banner/BLANCOBANDRA03.png";
import Slider69 from "../assets/images/interior-detail/interior14/banner/BLANCOBANDRA04.png";
import Slider70 from "../assets/images/interior-detail/interior14/banner/BLANCOBANDRA05.png";
import BLANCOBANDRA06 from "../assets/images/interior-detail/interior14/banner/BLANCOBANDRA06.png";
import BLANCOBANDRA07 from "../assets/images/interior-detail/interior14/banner/BLANCOBANDRA07.png";
import Section40 from "../assets/images/interior-detail/interior14/section/BLANCOBANDRA07.png";
import Section41 from "../assets/images/interior-detail/interior14/section/BLANCOBANDRA08.png";
import Section42 from "../assets/images/interior-detail/interior14/section/BLANCOBANDRA09.png";

import C14 from "../assets/images/interior-detail/interior14/BLANCOBANDRA_C.png";
/* interior14*/

/* interior15*/
import Slider71 from "../assets/images/interior-detail/interior15/banner/food01.png";
import Slider72 from "../assets/images/interior-detail/interior15/banner/food02.png";
import Slider73 from "../assets/images/interior-detail/interior15/banner/food03.png";
import Slider74 from "../assets/images/interior-detail/interior15/banner/food04.png";
import Slider75 from "../assets/images/interior-detail/interior15/banner/food05.png";
import food06 from "../assets/images/interior-detail/interior15/banner/food06.png";
import food07 from "../assets/images/interior-detail/interior15/banner/food07.png";
import food08 from "../assets/images/interior-detail/interior15/banner/food08.png";

import Section43 from "../assets/images/interior-detail/interior15/section/food09.png";
import Section44 from "../assets/images/interior-detail/interior15/section/food10.png";
import Section45 from "../assets/images/interior-detail/interior15/section/food11.png";

import C15 from "../assets/images/interior-detail/interior15/FOOD-STUDIO_C.png";
/* interior15*/

/* interior16*/
import Slider76 from "../assets/images/interior-detail/interior16/banner/TEAVILLAPUNE01.png";
import Slider77 from "../assets/images/interior-detail/interior16/banner/TEAVILLAPUNE02.png";
import Slider78 from "../assets/images/interior-detail/interior16/banner/TEAVILLAPUNE03.png";
import Slider79 from "../assets/images/interior-detail/interior16/banner/TEAVILLAPUNE04.png";
import Slider80 from "../assets/images/interior-detail/interior16/banner/TEAVILLAPUNE05.png";

import Section46 from "../assets/images/interior-detail/interior16/section/TEAVILLAPUNE06.png";
import Section47 from "../assets/images/interior-detail/interior16/section/TEAVILLAPUNE07.png";
import Section48 from "../assets/images/interior-detail/interior16/section/TEAVILLAPUNE08.png";

import C16 from "../assets/images/interior-detail/interior16/TEAVILLA-PUNE_C.png";
/* interior16*/

/* interior17*/
import Slider81 from "../assets/images/interior-detail/interior17/banner/TEAVILLAP01.png";
import Slider82 from "../assets/images/interior-detail/interior17/banner/TEAVILLAP02.png";
import Slider83 from "../assets/images/interior-detail/interior17/banner/TEAVILLAP03.png";
import Slider84 from "../assets/images/interior-detail/interior17/banner/TEAVILLAP04.png";
import Slider85 from "../assets/images/interior-detail/interior17/banner/TEAVILLAP05.png";
import TEAVILLAP06 from "../assets/images/interior-detail/interior17/banner/TEAVILLAP06.png";
import TEAVILLAP07 from "../assets/images/interior-detail/interior17/banner/TEAVILLAP07.png";
import TEAVILLAP08 from "../assets/images/interior-detail/interior17/banner/TEAVILLAP08.png";

import Section49 from "../assets/images/interior-detail/interior17/section/TEAVILLAP09.png";
import Section50 from "../assets/images/interior-detail/interior17/section/TEAVILLAP10.png";
import Section51 from "../assets/images/interior-detail/interior17/section/TEAVILLAP11.png";

import C17 from "../assets/images/interior-detail/interior17/TEAVILLA-P-_C.png";
/* interior17*/

/* interior18*/
import Slider86 from "../assets/images/interior-detail/interior18/banner/timessqaure01.png";
import Slider87 from "../assets/images/interior-detail/interior18/banner/timessqaure02.png";
import Slider88 from "../assets/images/interior-detail/interior18/banner/timessqaure03.png";
import Slider89 from "../assets/images/interior-detail/interior18/banner/timessqaure04.png";
import Slider90 from "../assets/images/interior-detail/interior18/banner/timessqaure05.png";

import Section52 from "../assets/images/interior-detail/interior18/section/timessqaure06.png";
import Section53 from "../assets/images/interior-detail/interior18/section/timessqaure07.png";
import Section54 from "../assets/images/interior-detail/interior18/section/timessqaure08.png";

import C18 from "../assets/images/interior-detail/interior18/TIMESSQUARE_C.png";
/* interior18*/

/* interior19*/
import Slider91 from "../assets/images/interior-detail/interior19/banner/TEAVILLAM01.png";
import Slider92 from "../assets/images/interior-detail/interior19/banner/TEAVILLAM02.png";
import Slider93 from "../assets/images/interior-detail/interior19/banner/TEAVILLAM03.png";
import Slider94 from "../assets/images/interior-detail/interior19/banner/TEAVILLAM04.png";
import Slider95 from "../assets/images/interior-detail/interior19/banner/TEAVILLAM05.png";
import TEAVILLAM06 from "../assets/images/interior-detail/interior19/banner/TEAVILLAM06.png";
import TEAVILLAM07 from "../assets/images/interior-detail/interior19/banner/TEAVILLAM07.png";
import TEAVILLAM08 from "../assets/images/interior-detail/interior19/banner/TEAVILLAM08.png";

import Section55 from "../assets/images/interior-detail/interior19/section/TEAVILLAM09.png";
import Section56 from "../assets/images/interior-detail/interior19/section/TEAVILLAM10.png";
import Section57 from "../assets/images/interior-detail/interior19/section/TEAVILLAM11.png";

import C19 from "../assets/images/interior-detail/interior19/TEA-VILLA-M_C.png";
/* interior19*/

/* interior20*/
import Slider96 from "../assets/images/interior-detail/interior20/banner/TEAVILLAH01.png";
import Slider97 from "../assets/images/interior-detail/interior20/banner/TEAVILLAH02.png";
import Slider98 from "../assets/images/interior-detail/interior20/banner/TEAVILLAH03.png";
import Slider99 from "../assets/images/interior-detail/interior20/banner/TEAVILLAH04.png";
import Slider100 from "../assets/images/interior-detail/interior20/banner/TEAVILLAH05.png";
import TEAVILLAH06 from "../assets/images/interior-detail/interior20/banner/TEAVILLAH06.png";
import TEAVILLAH07 from "../assets/images/interior-detail/interior20/banner/TEAVILLAH07.png";
import TEAVILLAH08 from "../assets/images/interior-detail/interior20/banner/TEAVILLAH08.png";

import Section58 from "../assets/images/interior-detail/interior20/section/TEAVILLAH09.png";
import Section59 from "../assets/images/interior-detail/interior20/section/TEAVILLAH10.png";
import Section60 from "../assets/images/interior-detail/interior20/section/TEAVILLAH11.png";

import C20 from "../assets/images/interior-detail/interior20/TEAVILLAH0_C.png";
/* interior20*/

/* interior21*/
import Slider101 from "../assets/images/interior-detail/interior21/banner/FEBRUARY01.png";
import Slider102 from "../assets/images/interior-detail/interior21/banner/FEBRUARY02.png";
import Slider103 from "../assets/images/interior-detail/interior21/banner/FEBRUARY03.png";
import Slider104 from "../assets/images/interior-detail/interior21/banner/FEBRUARY04.png";
import Slider105 from "../assets/images/interior-detail/interior21/banner/FEBRUARY05.png";
import Slider106 from "../assets/images/interior-detail/interior21/banner/FEBRUARY06.png";

import Section61 from "../assets/images/interior-detail/interior21/section/FEBRUARY07.png";
import Section62 from "../assets/images/interior-detail/interior21/section/FEBRUARY09.png";
import Section63 from "../assets/images/interior-detail/interior21/section/FEBRUARY10.png";

import Video21 from "../assets/images/interior-detail/interior21/video/february30.mov";
import C21 from "../assets/images/interior-detail/interior21/FEBRUARY_C.png";
/* interior21*/

/* interior22*/
import Slider107 from "../assets/images/interior-detail/interior22/banner/Avant01.jpg";
import Slider108 from "../assets/images/interior-detail/interior22/banner/Avant02.jpg";
import Slider109 from "../assets/images/interior-detail/interior22/banner/Avant03.jpg";
import Slider110 from "../assets/images/interior-detail/interior22/banner/Avant04.jpg";
import Slider111 from "../assets/images/interior-detail/interior22/banner/Avant05.jpg";
import Slider112 from "../assets/images/interior-detail/interior22/banner/Avant06.jpg";
import Slider113 from "../assets/images/interior-detail/interior22/banner/Avant07.jpg";
import Slider114 from "../assets/images/interior-detail/interior22/banner/Avant08.jpg";

import Section64 from "../assets/images/interior-detail/interior22/section/Avant09.jpg";
import Section65 from "../assets/images/interior-detail/interior22/section/Avant10.jpg";
import Section66 from "../assets/images/interior-detail/interior22/section/Avant11.jpg";

import Video22 from "../assets/images/interior-detail/interior22/video/Avant.mov";
import AVANT_C from "../assets/images/interior-detail/interior22/AVANT_C.png";

/* interior22*/

/* interior23*/
import Slider115 from "../assets/images/interior-detail/interior23/banner/ether01.jpg";
import Slider116 from "../assets/images/interior-detail/interior23/banner/ether02.jpg";
import Slider117 from "../assets/images/interior-detail/interior23/banner/ether03.jpg";
import Slider118 from "../assets/images/interior-detail/interior23/banner/ether04.jpg";
import Slider119 from "../assets/images/interior-detail/interior23/banner/ether05.jpg";
import Slider120 from "../assets/images/interior-detail/interior23/banner/ether06.jpg";
import Slider121 from "../assets/images/interior-detail/interior23/banner/ether07.jpg";
import Slider122 from "../assets/images/interior-detail/interior23/banner/ether08.jpg";

import Section67 from "../assets/images/interior-detail/interior23/section/ether09.jpg";
import Section68 from "../assets/images/interior-detail/interior23/section/ether10.jpg";
import Section69 from "../assets/images/interior-detail/interior23/section/ether11.jpg";

import C23 from "../assets/images/interior-detail/interior23/ETHER_C.png";
/* interior23*/

/* interior24*/
import Slider123 from "../assets/images/interior-detail/interior24/banner/GL01.jpg";
import Slider124 from "../assets/images/interior-detail/interior24/banner/GL02.jpg";
import Slider125 from "../assets/images/interior-detail/interior24/banner/GL03.jpg";
import Slider126 from "../assets/images/interior-detail/interior24/banner/GL04.jpg";
import Slider127 from "../assets/images/interior-detail/interior24/banner/GL05.jpg";
import Slider128 from "../assets/images/interior-detail/interior24/banner/GL06.jpg";
import Slider129 from "../assets/images/interior-detail/interior24/banner/GL07.jpg";
import Slider130 from "../assets/images/interior-detail/interior24/banner/GL08.jpg";

import Section70 from "../assets/images/interior-detail/interior24/section/GL09.jpg";
import Section71 from "../assets/images/interior-detail/interior24/section/GL10.jpg";
import Section72 from "../assets/images/interior-detail/interior24/section/GL11.jpg";
//import Video24 from "../assets/images/interior-detaVideo24il/interior24/video/IMG_3839.mov";
import C24 from "../assets/images/interior-detail/interior24/GL_C.png";
/* interior24*/

/* interior25*/
import Slider131 from "../assets/images/interior-detail/interior25/banner/jalaram01.jpg";
import Slider132 from "../assets/images/interior-detail/interior25/banner/jalaram02.jpg";
import Slider133 from "../assets/images/interior-detail/interior25/banner/jalaram03.jpg";
import Slider134 from "../assets/images/interior-detail/interior25/banner/jalaram04.jpg";
import Slider135 from "../assets/images/interior-detail/interior25/banner/jalaram05.jpg";
import Slider136 from "../assets/images/interior-detail/interior25/banner/jalaram06.jpg";
import Slider137 from "../assets/images/interior-detail/interior25/banner/jalaram07.jpg";
import Slider138 from "../assets/images/interior-detail/interior25/banner/jalaram08.jpg";

import Section73 from "../assets/images/interior-detail/interior25/section/jalaram09.jpg";
import Section74 from "../assets/images/interior-detail/interior25/section/jalaram10.jpg";
import Section75 from "../assets/images/interior-detail/interior25/section/jalaram11.jpg";
import C25 from "../assets/images/interior-detail/interior25/JALARAM_C.jpg";
/* interior25*/

/* interior26*/
import Slider139 from "../assets/images/interior-detail/interior26/banner/Teavillaoffice01.jpg";
import Slider140 from "../assets/images/interior-detail/interior26/banner/Teavillaoffice02.jpg";
import Slider141 from "../assets/images/interior-detail/interior26/banner/Teavillaoffice03.jpg";
import Slider142 from "../assets/images/interior-detail/interior26/banner/Teavillaoffice04.jpg";
import Slider143 from "../assets/images/interior-detail/interior26/banner/Teavillaoffice05.jpg";
import Slider144 from "../assets/images/interior-detail/interior26/banner/Teavillaoffice06.jpg";
import Slider145 from "../assets/images/interior-detail/interior26/banner/Teavillaoffice07.jpg";
import Slider146 from "../assets/images/interior-detail/interior26/banner/Teavillaoffice08.jpg";

import Section147 from "../assets/images/interior-detail/interior26/section/Teavillaoffice09.jpg";
import Section148 from "../assets/images/interior-detail/interior26/section/Teavillaoffice10.jpg";
import Section149 from "../assets/images/interior-detail/interior26/section/Teavillaoffice11.jpg";

import C26 from "../assets/images/interior-detail/interior26/Teavilla-office-_C.png";
/* interior26*/


/* interior27*/
import Slider150 from "../assets/images/interior-detail/interior27/banner/casa01.jpg";
import Slider151 from "../assets/images/interior-detail/interior27/banner/casa02.jpg";
import Slider152 from "../assets/images/interior-detail/interior27/banner/casa03.jpg";
import Slider153 from "../assets/images/interior-detail/interior27/banner/casa04.jpg";
import Slider154 from "../assets/images/interior-detail/interior27/banner/casa05.jpg";
import Slider155 from "../assets/images/interior-detail/interior27/banner/casa06.jpg";
import Slider156 from "../assets/images/interior-detail/interior27/banner/casa07.jpg";
import Slider157 from "../assets/images/interior-detail/interior27/banner/casa08.jpg";

import Section79 from "../assets/images/interior-detail/interior27/section/casa09.jpg";
import Section80 from "../assets/images/interior-detail/interior27/section/casa10.jpg";
import Section81 from "../assets/images/interior-detail/interior27/section/casa11.gif";
import Video27 from "../assets/images/interior-detail/interior27/video/CASANEUE.mov";
import C27 from "../assets/images/interior-detail/interior27/CASA_C.png";
/* interior27*/


/* interior28*/
import Slider158 from "../assets/images/interior-detail/interior28/banner/OR1.jpg";
import Slider159 from "../assets/images/interior-detail/interior28/banner/OR2.jpg";
import Slider160 from "../assets/images/interior-detail/interior28/banner/OR3.jpg";
import Slider161 from "../assets/images/interior-detail/interior28/banner/OR4.jpg";
import Slider162 from "../assets/images/interior-detail/interior28/banner/OR5.jpg";
import Slider163 from "../assets/images/interior-detail/interior28/banner/OR6.jpg";
import Slider164 from "../assets/images/interior-detail/interior28/banner/OR7.jpg";
import Slider165 from "../assets/images/interior-detail/interior28/banner/OR8.jpg";

import Section82 from "../assets/images/interior-detail/interior28/section/OR09.gif";
import Section83 from "../assets/images/interior-detail/interior28/section/OR10.jpg";
import Section84 from "../assets/images/interior-detail/interior28/section/OR11.jpg";
import Video28 from "../assets/images/interior-detail/interior28/video/Orangutan_Film.mp4";
import C28 from "../assets/images/interior-detail/interior28/ORANGATUN_C.png";
/* interior28*/



/* interior29*/
import Architec1 from "../assets/images/architec-detail/architec1/banner/K1.jpg";
import Architec2 from "../assets/images/architec-detail/architec1/banner/K2.jpg";
import Architec3 from "../assets/images/architec-detail/architec1/banner/K3.jpg";
import Architec4 from "../assets/images/architec-detail/architec1/banner/K4.jpg";
import Architec5 from "../assets/images/architec-detail/architec1/banner/K5.jpg";
import Architec6 from "../assets/images/architec-detail/architec1/banner/K6.jpg";

import Architec7 from "../assets/images/architec-detail/architec1/section/K9.jpg";
import Architec8 from "../assets/images/architec-detail/architec1/section/K10.jpg";
import Architec9 from "../assets/images/architec-detail/architec1/section/K11.jpg";

import C29 from "../assets/images/architec-detail/architec1/ARCHITECTURE-C.png";
/* interior29*/


/* interior30*/
import Digient01 from "../assets/images/interior-detail/interior29/banner/digient01.jpg";
import Digient02 from "../assets/images/interior-detail/interior29/banner/digient02.jpg";
import Digient03 from "../assets/images/interior-detail/interior29/banner/digient03.jpg";
import Digient04 from "../assets/images/interior-detail/interior29/banner/digient04.jpg";
import Digient05 from "../assets/images/interior-detail/interior29/banner/digient05.jpg";
import Digient06 from "../assets/images/interior-detail/interior29/banner/digient06.jpg";
import CDigient from "../assets/images/interior-detail/interior29/Digient1.png";

import D_07 from "../assets/images/interior-detail/interior29/section/D_07.jpg";
import D_08 from "../assets/images/interior-detail/interior29/section/D_08.jpg";
import D_09 from "../assets/images/interior-detail/interior29/section/D_09.jpg";
import Digient from "../assets/images/interior-detail/interior29/video/digient.mov";
/* interior30*/

/* interior31*/
import Di01 from "../assets/images/interior-detail/interior30/banner/di01.jpg";
import Di02 from "../assets/images/interior-detail/interior30/banner/di02.jpg";
import Di03 from "../assets/images/interior-detail/interior30/banner/di03.jpg";
import Di04 from "../assets/images/interior-detail/interior30/banner/di04.jpg";
import Di05 from "../assets/images/interior-detail/interior30/banner/di05.jpg";
import Di06 from "../assets/images/interior-detail/interior30/banner/di06.jpg";
import Di07 from "../assets/images/interior-detail/interior30/banner/di07.jpg";
import Di08 from "../assets/images/interior-detail/interior30/section/di08.jpg";
import Di09 from "../assets/images/interior-detail/interior30/section/di09.jpg";
import Di10 from "../assets/images/interior-detail/interior30/section/di10.jpg";
import CDi from "../assets/images/interior-detail/interior30/di_C.png";

/* interior31*/

/* interior32*/
import D01 from "../assets/images/interior-detail/interior31/banner/D01.jpg";
import D02 from "../assets/images/interior-detail/interior31/banner/D02.jpg";
import D03 from "../assets/images/interior-detail/interior31/banner/D03.jpg";
import D04 from "../assets/images/interior-detail/interior31/banner/D04.jpg";
import D05 from "../assets/images/interior-detail/interior31/banner/D05.jpg";
import D06 from "../assets/images/interior-detail/interior31/banner/D06.jpg";
import D07 from "../assets/images/interior-detail/interior31/section/D07.jpg";
import D08 from "../assets/images/interior-detail/interior31/section/D08.jpg";
import D09 from "../assets/images/interior-detail/interior31/section/D09.jpg";
import D_C from "../assets/images/interior-detail/interior31/D_C.png";
/* interior32*/

/* interior33*/
import Di1 from "../assets/images/interior-detail/interior32/banner/di01.jpg";
import Di2 from "../assets/images/interior-detail/interior32/banner/di02.jpg";
import Di3 from "../assets/images/interior-detail/interior32/banner/di03.jpg";
import Di4 from "../assets/images/interior-detail/interior32/banner/di04.jpg";
import Di5 from "../assets/images/interior-detail/interior32/banner/di05.jpg";
import Di6 from "../assets/images/interior-detail/interior32/banner/di06.jpg";
import Di7 from "../assets/images/interior-detail/interior32/section/di07.jpg";
import Di8 from "../assets/images/interior-detail/interior32/section/di08.jpg";
import Di9 from "../assets/images/interior-detail/interior32/section/di09.jpg";
import D4_C from "../assets/images/interior-detail/interior32/D4_C.png";
/* interior33*/


/* interior34*/
import DB1 from "../assets/images/interior-detail/interior33/banner/digientbeg01.png";
import DB2 from "../assets/images/interior-detail/interior33/banner/digientbeg02.png";
import DB3 from "../assets/images/interior-detail/interior33/banner/digientbeg03.png";
import DB4 from "../assets/images/interior-detail/interior33/banner/digientbeg04.png";
import DB5 from "../assets/images/interior-detail/interior33/section/digientbeg05.png";
import DB6 from "../assets/images/interior-detail/interior33/section/digientbeg06.png";
import DB7 from "../assets/images/interior-detail/interior33/section/diginetbeg07.png";
import DB_C from "../assets/images/interior-detail/interior33/digientBeginning_C.png";
/* interior34*/


/* interior35*/
import ABODE1 from "../assets/images/interior-detail/interior34/banner/abode01.png";
import ABODE2 from "../assets/images/interior-detail/interior34/banner/abode02.png";
import ABODE3 from "../assets/images/interior-detail/interior34/banner/abode03.png";
import ABODE4 from "../assets/images/interior-detail/interior34/banner/abode04.png";
import ABODE5 from "../assets/images/interior-detail/interior34/banner/abode05.png";
import ABODE6 from "../assets/images/interior-detail/interior34/banner/abode06.png";
import ABODE7 from "../assets/images/interior-detail/interior34/section/abode07.jpg";
import ABODE8 from "../assets/images/interior-detail/interior34/section/abode08.jpg";
import ABODE9 from "../assets/images/interior-detail/interior34/section/abode09.jpg";
import ABODEC from "../assets/images/interior-detail/interior34/abode_C.jpg";
import ABODEWATCH from "../assets/images/interior-detail/ABODE.svg";

import ABODEVIDEO from "../assets/images/interior-detail/interior34/video/abode_video.mov";


/* interior35*/

/* product1*/
import PMAIN1 from "../assets/images/products/product1/Blob_Candle Stand.png";
import PRODUCTM1 from "../assets/images/products/product1/inner/product1.png";
import PRODUCTS1 from "../assets/images/products/product1/inner/BLOB01.jpg";
import PRODUCTS2 from "../assets/images/products/product1/inner/BLOB02.jpg";
import PRODUCTS3 from "../assets/images/products/product1/inner/BLOB03.jpg";
/* product1*/

/* product2*/
import PMAIN2 from "../assets/images/products/product2/Transverse_Mirror.png";
import PRODUCTM2 from "../assets/images/products/product2/inner/mainproduct2.jpg";
import PRODUCTS4 from "../assets/images/products/product2/inner/TRANSVERSE01.jpg";
import PRODUCTS5 from "../assets/images/products/product2/inner/TRANSVERSE02.jpg";
import PRODUCTS6 from "../assets/images/products/product2/inner/TRANSVERSE03.jpg";
/* product2*/

/* product3*/
import PMAIN3 from "../assets/images/products/product3/Moon_Vase.png";
import PRODUCTM3 from "../assets/images/products/product3/inner/mainproduct3.jpg";
import PRODUCTS7 from "../assets/images/products/product3/inner/moon01.jpg";
import PRODUCTS8 from "../assets/images/products/product3/inner/moon02.jpg";
import PRODUCTS9 from "../assets/images/products/product3/inner/moon03.jpg";
/* product3*/

/* product4*/
import PMAIN4 from "../assets/images/products/product4/Tide_Bookends.png";
import PRODUCTM4 from "../assets/images/products/product4/inner/product4.jpg";
import PRODUCTS10 from "../assets/images/products/product4/inner/tide01.jpg";
import PRODUCTS11 from "../assets/images/products/product4/inner/tide02.jpg";
import PRODUCTS12 from "../assets/images/products/product4/inner/tide03.jpg";
/* product4*/


/* exhibition1*/
import EMAIN1 from "../assets/images/exhibition/exhibition1/ETHERPOPUP1.jpg";
import EXHIBITIONM1 from "../assets/images/exhibition/exhibition1/inner/ex1.jpg";
import EXHIBITIONS1 from "../assets/images/exhibition/exhibition1/inner/ether01.jpg";
import EXHIBITIONS2 from "../assets/images/exhibition/exhibition1/inner/ether02.jpg";
import EXHIBITIONS3 from "../assets/images/exhibition/exhibition1/inner/ether03.jpg";
/* exhibition1*/

/* exhibition2*/
import EMAIN2 from "../assets/images/exhibition/exhibition2/ETHERPOPUP2.jpg";
import EXHIBITIONM2 from "../assets/images/exhibition/exhibition2/inner/ex2.jpg";
import EXHIBITIONS4 from "../assets/images/exhibition/exhibition2/inner/ether01.jpg";
import EXHIBITIONS5 from "../assets/images/exhibition/exhibition2/inner/ether02.jpg";
import EXHIBITIONS6 from "../assets/images/exhibition/exhibition2/inner/ether03.jpg";
/* exhibition2*/

/* exhibition3*/
import EMAIN3 from "../assets/images/exhibition/exhibition3/Indiaplays.jpg";
import EXHIBITIONM3 from "../assets/images/exhibition/exhibition3/inner/ex3.jpg";
import EXHIBITIONS7 from "../assets/images/exhibition/exhibition3/inner/IP01.jpg";
import EXHIBITIONS8 from "../assets/images/exhibition/exhibition3/inner/IP02.jpg";
import EXHIBITIONS9 from "../assets/images/exhibition/exhibition3/inner/IP03.jpg";
/* exhibition3*/

/* exhibition4*/
import EMAIN4 from "../assets/images/exhibition/exhibition4/SWELL.png";
import EXHIBITIONM4 from "../assets/images/exhibition/exhibition4/inner/ex4.png";
import EXHIBITIONS10 from "../assets/images/exhibition/exhibition4/inner/SWELL01.png";
import EXHIBITIONS11 from "../assets/images/exhibition/exhibition4/inner/SWELL02.png";
import EXHIBITIONS12 from "../assets/images/exhibition/exhibition4/inner/SWELL03.png";
/* exhibition4*/


/* FURNITURE*/
import FURNITURE1 from "../assets/images/furniture/strokes_bench.png";
import FURNITURE2 from "../assets/images/furniture/bulge_chair.png";
import FURNITURE3 from "../assets/images/furniture/curl_lounger.png";
import FURNITURE4 from "../assets/images/furniture/octa_cabinet.png";
import FURNITURE5 from "../assets/images/furniture/gravity_side_table.png";
import FURNITURE6 from "../assets/images/furniture/matter_centre_table.png";
import FURNITURE7 from "../assets/images/furniture/skew_armchair.png";
import FURNITURE8 from "../assets/images/furniture/tide_console.png";
import FURNITURE9 from "../assets/images/furniture/tsunami_dining_table.png";
import FURNITURE10 from "../assets/images/furniture/edge_wall_light.png";
import FURNITURE11 from "../assets/images/furniture/tsunami_console.png";
import FURNITURE12 from "../assets/images/furniture/billow_wall_light.png";
import FURNITURE13 from "../assets/images/furniture/Kelvin_Luxe_Pendant_Light.png";
import FURNITURE14 from "../assets/images/furniture/Kelvin_Luxe_Pendant_Light_copy.png";
import FURNITURE15 from "../assets/images/furniture/breaking_rug.png";
import FURNITURE16 from "../assets/images/furniture/capillary_rug.png";
import FURNITURE17 from "../assets/images/furniture/surge_rug.png";

/* FURNITURE*/

export const INTERIOR_TYPE_CONST = {
  HOSPITALITY: 'Hospitality',
  COMMERCIAL: 'Commercial',
  RESIDENTIAL: 'Residential',
  INSTITUTIONAL: 'Institutional',
}

export const ARCHITECTURE_TYPE_CONST = {
  ARCHITECTURE: 'Architecture',
}

export const ARCHITECTURE_TYPES = [
  {
    name: ARCHITECTURE_TYPE_CONST.ARCHITECTURE,
    href: 'Architecture',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras pellentesque venenatis magna, eget bibendum massa iaculis et. Mauris facilisis, leo et scelerisque porta.'
  },
]

export const EXHIBITION_TYPE_CONST = {
  EXHIBITION: 'Exhibition',
}

export const EXHIBITION_TYPES = [
  {
    name: EXHIBITION_TYPE_CONST.EXHIBITION,
    href: 'Exhibition',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras pellentesque venenatis magna, eget bibendum massa iaculis et. Mauris facilisis, leo et scelerisque porta.'
  },
]

export const PRODUCT_TYPE_CONST = {
  PRODUCT: 'Product',
}

export const PRODUCT_TYPES = [
  {
    name: PRODUCT_TYPE_CONST.PRODUCT,
    href: 'Product',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras pellentesque venenatis magna, eget bibendum massa iaculis et. Mauris facilisis, leo et scelerisque porta.'
  },
]

export const FURNITURE_TYPE_CONST = {
  FURNITURE: 'Furniture',
}

export const FURNITURE_TYPES = [
  {
    name: FURNITURE_TYPE_CONST.FURNITURE,
    href: 'Furniture',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras pellentesque venenatis magna, eget bibendum massa iaculis et. Mauris facilisis, leo et scelerisque porta.'
  },
]

export const INTERIOR_TYPES = [
  {
    name: INTERIOR_TYPE_CONST.HOSPITALITY,
    href: 'hospitality',
    text: 'With the expertise in understanding the individualistic essence of each restaurant, cafe, bar, or hotel, we always want to create experiences that have the potential to evoke Cultural, Social, and Sensory Sentiments.'
  },

  {
    name: INTERIOR_TYPE_CONST.COMMERCIAL,
    href: 'commercial',
    text: 'With absolute respect for the conventional and orthodox norm of designing workspaces and retail experiences, we love the idea of parading into a commercial space that reflects drama and eclecticism.'
  },
  {
    name: INTERIOR_TYPE_CONST.RESIDENTIAL,
    href: 'residential',
    text: 'Understanding your brief and rendering a home that has a soft and deliquescent character, a melting-away warm and comfortable environment, and yet having a personality that exudes class and artistic luxury is what we vow to create and furnish.'
  },
  {
    name: INTERIOR_TYPE_CONST.INSTITUTIONAL,
    href: 'insitutional',
    text: 'Our approach with Institutional projects is adaptive design. We believe in creating exceptional interior design finishes and specialty products to transform the aesthetics of institutional organisations.'
  }
]

export const furnitures = [
  {
    id: 1,
    picture: FURNITURE1,
    url: "http://www.oneoonestudios.com/",
    text: "Strokes Bench",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 2,
    picture: FURNITURE2,
    url: "http://www.oneoonestudios.com/",
    text: "Bulge Chair",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 3,
    picture: FURNITURE3,
    url: "http://www.oneoonestudios.com/",
    text: "Curl Lounger",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 4,
    picture: FURNITURE4,
    url: "http://www.oneoonestudios.com/",
    text: "Octa Cabinet",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 5,
    picture: FURNITURE5,
    url: "http://www.oneoonestudios.com/",
    text: "Gravity Side Table",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 6,
    picture: FURNITURE6,
    url: "http://www.oneoonestudios.com/",
    text: "Matter Centre Table",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 7,
    picture: FURNITURE7,
    url: "http://www.oneoonestudios.com/",
    text: "Skew Armchair",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 8,
    picture: FURNITURE8,
    url: "http://www.oneoonestudios.com/",
    text: "Tide Console",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 9,
    picture: FURNITURE9,
    url: "http://www.oneoonestudios.com/",
    text: "Tsunami Dining Table",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 10,
    picture: FURNITURE10,
    url: "http://www.oneoonestudios.com/",
    text: "Edge WWall Light",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 11,
    picture: FURNITURE11,
    url: "http://www.oneoonestudios.com/",
    text: "Tsunami Console",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 12,
    picture: FURNITURE12,
    url: "http://www.oneoonestudios.com/",
    text: "Billow Wall Light",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 13,
    picture: FURNITURE13,
    url: "http://www.oneoonestudios.com/",
    text: "Kelvin Luxe Pendant Light",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 14,
    picture: FURNITURE14,
    url: "http://www.oneoonestudios.com/",
    text: "Kelvin Luxe Pendant Light Copy",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 15,
    picture: FURNITURE15,
    url: "http://www.oneoonestudios.com/",
    text: "Breaking Rug",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 16,
    picture: FURNITURE16,
    url: "http://www.oneoonestudios.com/",
    text: "Capillary Rug",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
  {
    id: 17,
    picture: FURNITURE17,
    url: "http://www.oneoonestudios.com/",
    text: "Surge Rug",
    type: FURNITURE_TYPE_CONST.FURNITURE, 
  },
 ]
export const architectures = [
  {
    id: 1,
    picture: Interiors29,
    url: "/architectures-detail/1",
    text: "Kunenama Modern, Lonavala",
    type: ARCHITECTURE_TYPE_CONST.ARCHITECTURE,
    detail: {
      id: 1,
      mainHeading: "Kunenama Modern, Lonavala",
      cHeading: "PRAGMATIC MODERNISM",
      watchIcon:"", cPicture1: C29,
      cText1: "‘Where civilization evaporates to contentment.’",
      content: "A star gazer's dream, these villas on premium elevated plots capture the most stunning views of mountains and valleys. Set at 2000 ft in the pristine backdrop of Sahaydri’s on one side and the valley on the other, Kunenama is a picture perfect gateway to your second home.",
      subContent1: "Kunenama MODERN brings you an exclusive, unique and exquisite set of courtyard villas in the pristine backdrops of Sahyadris in Lonavala.",
      subContent2: "A personal sense of luxury is brought to life in a legendary destination that conjures an authentic journey of both elegance and glamour.",
      video: "",
      middleSectionPictures: [
        { id: 1, picture: Architec7, alt: "slider-1",  },
        { id: 2, picture: Architec8, alt: "slider-2",  },
        { id: 3, picture: Architec9, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Architec1, alt: "slider-1",  },
        { id: 2, picture: Architec2, alt: "slider-2",  },
        { id: 3, picture: Architec3, alt: "slider-3",  },
        { id: 4, picture: Architec4, alt: "slider-4",  },
        { id: 5, picture: Architec5, alt: "slider-5",  },
		{ id: 6, picture: Architec6, alt: "slider-5",  }
      ],
    }
  },
 ]

export const products = [
  {
    id: 1,
    picture: PMAIN1,
    url: "/products-detail/1",
    text: "Blob </br>Candle Stand",
    type: PRODUCT_TYPE_CONST.PRODUCT,
    detail: {
      id: 1,
	  mainHeading: "Blob Candle Stand",
      content: "Add a splash of class in a room of your choice with our stunning candle stands. Watch the mingling of classic candles in new-age candle stands to give impressive character to your room.",
      middleSectionPictures: [
        { id: 1, picture: PRODUCTS1, alt: "slider-1",  },
        { id: 2, picture: PRODUCTS2, alt: "slider-2",  },
        { id: 3, picture: PRODUCTS3, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: PRODUCTM1, alt: "slider-1",  }
      ],
    }
  },
  {
    id: 2,
    picture: PMAIN2,
    url: "/products-detail/2",
    text: "Transverse Mirror",
    type: PRODUCT_TYPE_CONST.PRODUCT,
    detail: {
      id: 2,
	  mainHeading: "Transverse Mirror",
	  content: "With endless composition combinations to suit your taste, this transverse mirror with a hint of midnight blue is the best way to start your day. Brightening up your space and lifting you up as you get ready for your day, this is just you and your room needs to look and feel their best.",
      middleSectionPictures: [
        { id: 1, picture: PRODUCTS4, alt: "slider-1",  },
        { id: 2, picture: PRODUCTS5, alt: "slider-2",  },
        { id: 3, picture: PRODUCTS6, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: PRODUCTM2, alt: "slider-1",  }
      ],
    }
  },
  {
    id: 3,
    picture: PMAIN3,
    url: "/products-detail/3",
    text: "Moon Vase",
    type: PRODUCT_TYPE_CONST.PRODUCT,
    detail: {
      id: 3,
	  mainHeading: "Moon Vase",
      content: "Taking inspiration from the moon that controls the tides, this unique angled flower vase brims with freshness. Place your favourite flower arrangement in this distinctive piece and watch how it uplifts your space, brightening its aura.",
      middleSectionPictures: [
        { id: 1, picture: PRODUCTS7, alt: "slider-1",  },
        { id: 2, picture: PRODUCTS8, alt: "slider-2",  },
        { id: 3, picture: PRODUCTS9, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: PRODUCTM3, alt: "slider-1",  }
      ],
    }
  },
  {
    id: 4,
    picture: PMAIN4,
    url: "/products-detail/4",
    text: "Tide Bookends",
    type: PRODUCT_TYPE_CONST.PRODUCT,
    detail: {
      id: 4,
	  mainHeading: "Tide Bookends",
	  content: "Books add a touch of wisdom to a space, our bookends add a touch of style. Crafted in rufous red, this two-piece set complements each other. The same yet different at the same time these bookends will add a pop of colour to any room, effortlessly. What’s more? They go with different design styles too!",
      middleSectionPictures: [
        { id: 1, picture: PRODUCTS10, alt: "slider-1",  },
        { id: 2, picture: PRODUCTS11, alt: "slider-2",  },
        { id: 3, picture: PRODUCTS12, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: PRODUCTM4, alt: "slider-1",  }
      ],
    }
  },
 ]


export const exhibitions = [
  {
    id: 1,
    picture: EMAIN1,
    url: "/exhibitions-detail/1",
    text: "Ether Pop Up 1",
    type: EXHIBITION_TYPE_CONST.EXHIBITION,
    detail: {
      id: 1,
	  mainHeading: "Ether Pop Up 1",
      middleSectionPictures: [
        { id: 1, picture: EXHIBITIONS1, alt: "slider-1",  },
        { id: 2, picture: EXHIBITIONS2, alt: "slider-2",  },
        { id: 3, picture: EXHIBITIONS3, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: EXHIBITIONM1, alt: "slider-1",  }
      ],
    }
  },
  {
    id: 2,
    picture: EMAIN2,
    url: "/exhibitions-detail/2",
    text: "Ether Pop Up 2",
    type: EXHIBITION_TYPE_CONST.EXHIBITION,
    detail: {
      id: 2,
	  mainHeading: "Ether Pop Up 2",
	  middleSectionPictures: [
        { id: 1, picture: EXHIBITIONS4, alt: "slider-1",  },
        { id: 2, picture: EXHIBITIONS5, alt: "slider-2",  },
        { id: 3, picture: EXHIBITIONS6, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: EXHIBITIONM2, alt: "slider-1",  }
      ],
    }
  },
  {
    id: 3,
    picture: EMAIN3,
    url: "/exhibitions-detail/3",
    text: "Indiaplays",
    type: EXHIBITION_TYPE_CONST.EXHIBITION,
    detail: {
      id: 3,
	  mainHeading: "Indiaplays",
      middleSectionPictures: [
        { id: 1, picture: EXHIBITIONS7, alt: "slider-1",  },
        { id: 2, picture: EXHIBITIONS8, alt: "slider-2",  },
        { id: 3, picture: EXHIBITIONS9, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: EXHIBITIONM3, alt: "slider-1",  }
      ],
    }
  },
  {
    id: 4,
    picture: EMAIN4,
    url: "/exhibitions-detail/4",
    text: "Swell",
    type: EXHIBITION_TYPE_CONST.EXHIBITION,
    detail: {
      id: 4,
	  mainHeading: "Swell",
	  middleSectionPictures: [
        { id: 1, picture: EXHIBITIONS10, alt: "slider-1",  },
        { id: 2, picture: EXHIBITIONS11, alt: "slider-2",  },
        { id: 3, picture: EXHIBITIONS12, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: EXHIBITIONM4, alt: "slider-1",  }
      ],
    }
  },
 ]



export const interiors = [
  
  {
    id: 1, picture: Interiors8, url: "/interiors-detail/1",
    text: "SHALOM",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 1,
      mainHeading: "SHALOM",
      cHeading: "A MOROCCAN TREASURE",
      watchIcon:"", cPicture1: C8,
      cText1: "‘An exquisite mix of color and texture’",
      content: "To foster an impactful ambience of moroccan-inspired decor some woven accent furniture, succulent plants, and geometric flooring patterns were used to supplement the existing style.",
      subContent1: "Shalom brings you an exclusive, unique, and exquisite experience of the modern moroccan lounge.",
      subContent2: "The design is accentuated with colorful accents and patterned fabrics featuring ornate and plush furniture. Ornamental lanterns and accent pieces are added to add Moroccan flair to the whole ambience.",
      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section22, alt: "slider-1",  },
        { id: 2, picture: Section23, alt: "slider-2",  },
        { id: 3, picture: Section24, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider36, alt: "slider-1",  },
        { id: 2, picture: Slider37, alt: "slider-2",  },
        { id: 3, picture: Slider38, alt: "slider-3",  },
        { id: 4, picture: Slider39, alt: "slider-4",  },
        { id: 5, picture: Slider40, alt: "slider-5",  },
        { id: 6, picture: SHALOM06, alt: "slider-3",  },
        { id: 7, picture: SHALOM07, alt: "slider-4",  },
        { id: 8, picture: SHALOM08, alt: "slider-5",  }
      ],
    }
  },
  {
    id: 2,
    picture: Interiors1,
    url: "/interiors-detail/2",
    text: "WineVilla, Juhu",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 2,
      mainHeading: "WineVilla, Juhu",
      cHeading: "ART IN ARCHITECTURE",
      watchIcon:Winevilla, cPicture1: C1,
      cText1: "‘Let your soul and spirit fly’",
      content: "Smell the sea and feel the sky </br>Let your soul and spirit fly.</br></br>These were the words that influenced the designing of Wine Villa. A place where art met architecture in every nook and niche.",
      subContent1: "With an atmosphere inspired by its seaside location, one can almost feel the waves coming in. The lighting and ceiling are made to match that of water and waves.",
      subContent2: "Sophisticated art and patterns adorn the bold walls. Bright colours of wine and the sea entwine to create a surreal experience, both by night and day.</br> A place with a lot of thought and effort gone into the little things to turn your wine into bottled poetry.",
      video: Video1,
      middleSectionPictures: [
        { id: 1, picture: Section1, alt: "slider-1",  },
        { id: 2, picture: Section2, alt: "slider-2",  },
        { id: 3, picture: Section3, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider1, alt: "slider-1",  },
        { id: 2, picture: Slider2, alt: "slider-2",  },
        { id: 3, picture: Slider3, alt: "slider-3",  },
        { id: 4, picture: Slider4, alt: "slider-4",  },
        { id: 5, picture: Slider5, alt: "slider-5",  }
      ],
    }
  },
  {
    id: 3, picture: Interiors9,
    url: "/interiors-detail/3",
    text: "BOMBAY COCKTAIL BAR",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 3,
      mainHeading: "BOMBAY COCKTAIL BAR",
      cHeading: "THEATRICAL BEGUILE",
      watchIcon:"", cPicture1: C9,
      cText1: "‘Cohesive Spacious Layout’",
      content: "The layout and decor give you a visually stimulating experience at bombay cocktail bar. Contemporary elements, like the geometrical pattern shown on the blue led glowing bar counter, and colorful lightings are focal points of the concept. While a smooth blend of furniture, light, and placement of innovative art pieces are the key ingredients for this modern cosmopolitan bar design.",
      subContent1: "A blend of classical architecture with sophistication, Bombay Cocktail Bar offers you a theatrical experience with amphitheatres and VIP booths overlooking the central dance arena and minutely detailed bar back acts as the perfect throne to this setting.",
      subContent2: "A dynamic vibe and exotic aura distributes itself evenly around a central piece. A perfect timeless design that follows the rhythm of contrast, repetition, transition, and progression as we move from one area to another.",
      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section25, alt: "slider-1",  },
        { id: 2, picture: Section26, alt: "slider-2",  },
        { id: 3, picture: Section27, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider41, alt: "slider-1",  },
        { id: 2, picture: Slider42, alt: "slider-2",  },
        { id: 3, picture: Slider43, alt: "slider-3",  },
        { id: 4, picture: Slider44, alt: "slider-4",  },
        { id: 5, picture: Slider45, alt: "slider-5",  },
        { id: 6, picture: bombay06, alt: "slider-3",  },
        { id: 7, picture: bombay07, alt: "slider-4",  },
        { id: 8, picture: bombay08, alt: "slider-5",  }
      ],
    }
  },
  {
    id: 4, picture: Interiors12, url: "/interiors-detail/4",
    text: "BLANCO, Andheri",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 4,
      mainHeading: "BLANCO, Andheri",
      cHeading: "THE ALLURE OF GRECO ROMAN",
      watchIcon:"", cPicture1: C12,
      cText1: "‘ The classical Harmony’",
      content: "The love for the old and the new brings together the ultimate interior sophistication where function and shapes go beyond the traditional and develop the most creative solutions for this hospitality space that exuberates luxury in simplicity.",
      subContent1: "Blanco is created with a perfect combination of elements of the Greco-Roman world.",
      subContent2: "The design is chromatically influenced by nature and anchored in simplicity and openness. The graceful proportions of these interiors have been accentuated with natural color palettes and materials. To add more depth to the neutral color scheme, the purplish red tones of bougainvillaea were used to achieve the asceticism of Greek style.",

      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section34, alt: "slider-1",  },
        { id: 2, picture: Section35, alt: "slider-2",  },
        { id: 3, picture: Section36, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider56, alt: "slider-1",  },
        { id: 2, picture: Slider57, alt: "slider-2",  },
        { id: 3, picture: Slider58, alt: "slider-3",  },
        { id: 4, picture: Slider59, alt: "slider-4",  },
        { id: 5, picture: Slider60, alt: "slider-5",  },
        { id: 6, picture: BLANCO06, alt: "slider-2",  },
        { id: 7, picture: BLANCO07, alt: "slider-3",  },
        { id: 8, picture: BLANCO08, alt: "slider-4",  }
      ],
    }
  },
  {
    id: 5, picture: Interiors14,
    url: "/interiors-detail/5",
    text: "BLANCO, Bandra",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 5,
      mainHeading: "BLANCO, Bandra",
      watchIcon:"", cPicture1: C14,
      cHeading: "GREEK CHARM",
      cText1: "‘ A true oasis of relaxation.’",
      content: "The love for the old and the new brings together the ultimate interior sophistication where function and shapes go beyond the traditional and develop the most creative solutions for this hospitality space that exuberates luxury in simplicity.",
      subContent1: "Blanco is created with a perfect combination of elements of antiquity and modernity.",
      subContent2: "The design is chromatically influenced by nature and anchored in simplicity and openness. The graceful proportions of these interiors have been accentuated with natural color palettes and materials. To add more depth to the neutral color scheme, the purplish red tones of bougainvillaea were used to achieve the asceticism of Greek style.",
      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section40, alt: "slider-1",  },
        { id: 2, picture: Section41, alt: "slider-2",  },
        { id: 3, picture: Section42, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider66, alt: "slider-1",  },
        { id: 2, picture: Slider68, alt: "slider-3",  },
        { id: 3, picture: Slider69, alt: "slider-4",  },
        { id: 4, picture: Slider70, alt: "slider-5",  },
        { id: 5, picture: BLANCOBANDRA06, alt: "slider-5",  },
		{ id: 6, picture: BLANCOBANDRA07, alt: "slider-5",  }
      ],
    }
  },
    {
    id: 6, picture: February30,
    url: "/interiors-detail/6",
    text: "February 30, Andheri",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 6,
      mainHeading: "February 30, Andheri",
      cHeading: "GALACTIC AMBIENCE",
      watchIcon:Feb30, cPicture1: C21,
      cText1: "‘ futuristic grammar of architecture’",
      content: "An ingenious play of materials, textures & hues unify to weave together the off-beat ambience at february 30. The curation of the intriguing venue has been reiterated at the façade level that uses stark materiality, and an interplay of reflections & lighting to create a structure that has a fleeting presence in its context. The elevation of the built form has been cladded with mirrored steel panels that brilliantly reflect the imagery of its surroundings. The entangled cloud rope light feature suspended in the frontal section against the façade makes for a signature element in the design scheme of the venue. The elevation’s visually bipolar attribute gives it a camouflaged appearance through the day as it blends into its environment. During the evenings the elevation casts majestic reflections upon its surface kudos to the statement lighting feature that appears as though it is floating mid-air.",
      subContent1: "The ambience at February 30, takes inspiration from a futuristic grammar of architecture, elements of aerospace themed aesthetics & the design sensibilities of art director Virgil Abloh. The layout of the built space consists of a G+1 structure, with a cumulative of 2,800 sq. ft of indoor built-up space & 400 sq. ft of an attached outdoor section on the ground floor.",
      subContent2: "The interior scheme of the venue utilizes innovative design elements that create an atmosphere that is a cusp between interstellar rawness & an astronomical character. The ground level bar & lounge sections embody the look & feel of a galactic ambience where backlit stone veneer surfaces, an iconic lunar inspired bar unit & tinted finishes amalgamate to build on the layered suspense of the space. Crevices, a sporadic arrangement of lighting beams, mirrored surfaces & a monochrome color palette play the high notes in the designed space.",
      video: Video21,
      middleSectionPictures: [
        { id: 1, picture: Section61, alt: "slider-1",  },
        { id: 2, picture: Section62, alt: "slider-2",  },
        { id: 3, picture: Section63, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider101, alt: "slider-1",  },
        { id: 2, picture: Slider102, alt: "slider-2",  },
        { id: 3, picture: Slider103, alt: "slider-3",  },
        { id: 4, picture: Slider104, alt: "slider-4",  },
        { id: 5, picture: Slider105, alt: "slider-5",  },
        { id: 5, picture: Slider106, alt: "slider-5",  }
      ],
    }
  },
  {
    id: 7, picture: Interiors16, url: "/interiors-detail/7",
    text: "Tea villa cafe, Pune",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 7,
      mainHeading: "Tea villa cafe, Pune",
      cHeading: "VISUAL ENCHANT",
      watchIcon:"", cPicture1: C16,
      cText1: "‘Pattern brings unity.’",
      content: "Patterns are a sure way of creating drama in space. They bring energy, interest, and contrast into the design, and when patterns are done well, an eclectic space like this one is born.",
      subContent1: "The tea villa cafe, Pune is the agglomerate of changing facets, a layered facade, and projects itself as an iconic monolith of sorts with a stark presence on a corner plot.",
      subContent2: "This venue with its immaculate design elements makes for an inviting space for one and all!",
      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section46, alt: "slider-1",  },
        { id: 2, picture: Section47, alt: "slider-2",  },
        { id: 3, picture: Section48, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider76, alt: "slider-1",  },
        { id: 2, picture: Slider77, alt: "slider-2",  },
        { id: 3, picture: Slider78, alt: "slider-3",  },
        { id: 4, picture: Slider79, alt: "slider-4",  },
        { id: 5, picture: Slider80, alt: "slider-5",  }
      ],
    }
  },
  {
    id: 8, picture: Interiors11, url: "/interiors-detail/8",
    text: "TEA VILLA CAFE, KANDIVALI",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 8,
      mainHeading: "TEA VILLA CAFE, KANDIVALI",
      cHeading: "EUROGENIC FLAIR",
      watchIcon:"", cPicture1: C11,
      cText1: "‘A travers l'Europe’",
      content: "Freshness and intricacy of the French colour palette used, attributes more vitality to the space.",
      subContent1: "A European Street comes to life, with cobblestone walkway, beautiful storefronts and humane scale.",
      subContent2: "A cafe that beholds a luxurious atmosphere, sophisticated details and ornamental accents all of which recreate the old world charm and poise of classic european coffeehouses.",
      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section31, alt: "slider-1",  },
        { id: 2, picture: Section32, alt: "slider-2",  },
        { id: 3, picture: Section33, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider51, alt: "slider-1",  },
        { id: 2, picture: Slider52, alt: "slider-2",  },
        { id: 3, picture: Slider53, alt: "slider-3",  },
        { id: 4, picture: Slider54, alt: "slider-4",  },
        { id: 5, picture: Slider55, alt: "slider-5",  },
        { id: 6, picture: Teavilla06, alt: "slider-3",  },
        { id: 7, picture: Teavilla07, alt: "slider-4",  },
        { id: 8, picture: Teavilla08, alt: "slider-5",  }
      ],
    }
  },
 
  {
    id: 9, picture: Interiors13,
    url: "/interiors-detail/9",
    text: "TeaVilla Cafe Indore",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 9,
      mainHeading: "TeaVilla Cafe Indore",
      cHeading: "PASTEL WONDERLAND",
      watchIcon:"", cPicture1: C13,
      cText1: "‘Wes Andersen-esque aesthetic.’",
      content: "Adhering to a wes andersen-esque aesthetic, the 2500 sq. Ft space includes indoor, outdoor, and mezzanine level seating that can seat up to 140 patrons. The mezzanine level consists of a community table that can seat up to 25 people. The ground floor has a live tea counter, which is the element central to the café. The most striking element is the parametric ombre ceiling, constructed of pvc pipes of varying lengths, which creates drama as it engulfs the central column.",
      subContent1: "Ensconced within the heart of the south tukoganj neighbourhood in indore lies the newest entrant in the tea villa café family. The café centres around the concept of ‘pastel wonderland’, which involves the use of a palette of pastel shades juxtaposed with gold and dark green, to create a photo-worthy space.",
      subContent2: "There is a recurring use of arches as a design element throughout the space. Arched fenestrations in the façade act as framing elements between the interior and the exterior of the café. The subtlety of terrazzo flooring used further adds to the scheme.",
      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section37, alt: "slider-1",  },
        { id: 2, picture: Section38, alt: "slider-2",  },
        { id: 3, picture: Section39, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider61, alt: "slider-1",  },
        { id: 2, picture: Slider62, alt: "slider-2",  },
        { id: 3, picture: Slider63, alt: "slider-3",  },
        { id: 4, picture: Slider64, alt: "slider-4",  },
        { id: 5, picture: Slider65, alt: "slider-5",  },
        { id: 6, picture: Teavilla1306, alt: "slider-4",  },
        { id: 7, picture: Teavilla1307, alt: "slider-5",  }
      ],
    }
  },
  
  {
    id: 10, picture: Interiors17, url: "/interiors-detail/10",
    text: "TeaVilla Cafe. Prabhadevi",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 10,
      mainHeading: "TeaVilla Cafe, Prabhadevi",
      cHeading: "NEO INDIAN INTERIORS",
      watchIcon:"", cPicture1: C17,
      cText1: "‘All things poised & elegant.’",
      content: "At an interior level, a few design elements play the high notes & are used in varying scales & forms to unify the grammar of the space. The walls are neutral-toned but have been accented with murals & elements that exude bright hues. The custom-designed furniture for the space is sleek & adds a hint of colour into the volume. Two accent walls on the far corners have focal elements like a backlit wall & 3d lotus sculptural motif, that visually balance the horizontality of the floor plan. Bands of geometric arrows that function as unifying visual elements are omnipresent through the space atop of columns & beams. Despite the presence of multiple structural elements, with the right use of a palette of design treatments the space looks whole & seamless. The facade also embodies the design identity & has a grounded demeanour that gives the venue a landmark-like presence. The exterior elevation is also a mix of soft pastel hues, floral motifs, geometric elements & inlay work that stand for the modern-jaipur theme.",
      subContent1: "With previously designed branches, the firm's take on the design scheme usually stemmed from a modern & eclectic thematic inspiration. With the intention of lending this branch a persona that tied it in seamlessly with its surroundings, colab company worked with a neo-indian concept giving it a touch of its own. The duration from start to finish lasted from march to june 2019 & was a design-intense process.",
      subContent2: "An overall ‘jaipur’ theme was curated & envisioned by the architects to give the space its ambience & look. Jaipur as an indian city is known to have an individualistic style & boasts of a concoction of the old & the new. The building was initially a hospital structure & its ground level + terrace layout was used as the skeleton. The conversion of the hospital premises into a restaurant posed as a great challenge when it came to a large number of structural elements like columns & beams that dominated the layout. These elements were used ingeniously as design features rather than viewed as a hindrance.",

      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section49, alt: "slider-1",  },
        { id: 2, picture: Section50, alt: "slider-2",  },
        { id: 3, picture: Section51, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider81, alt: "slider-1",  },
        { id: 2, picture: Slider82, alt: "slider-2",  },
        { id: 3, picture: Slider83, alt: "slider-3",  },
        { id: 4, picture: Slider84, alt: "slider-4",  },
        { id: 5, picture: Slider85, alt: "slider-5",  },
        { id: 6, picture: TEAVILLAP06, alt: "slider-3",  },
        { id: 7, picture: TEAVILLAP07, alt: "slider-4",  },
        { id: 8, picture: TEAVILLAP08, alt: "slider-5",  }
      ],
    }
  }, 
   {
    id: 11, picture: Interiors19, url: "/interiors-detail/11",
    text: "Tea Villa Cafe, Mumbai",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 11,
      mainHeading: "Tea Villa Cafe, Mumbai",
      cHeading: "ROCOCO DELICACY",
      watchIcon:"", cPicture1: C19,
      cText1: "‘The Whimsical Pastels’",
      content: "A fabulous shade of tiffany blue here freshens up the atmosphere and creates the exquisite decor that blends harmoniously with the blush pinks, greys, and subtle warm textures.",
      subContent1: "A scrumptious space in a spectrum of the Rococo palette(pastel hues), devoid of visual clutter embodies a playful irreverence at Tea Villa Cafe, Mumbai.",
      subContent2: "The design of the cafe is characterized by contemporary round forms, arches, and the use of velour fabrics. The pastel aura is enhanced by delicate warm metallics that bring a luxurious mood to the space, maintaining the right balance between the play of light and colour.",
      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section55, alt: "slider-1",  },
        { id: 2, picture: Section56, alt: "slider-2",  },
        { id: 3, picture: Section57, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider91, alt: "slider-1",  },
        { id: 2, picture: Slider92, alt: "slider-2",  },
        { id: 3, picture: Slider93, alt: "slider-3",  },
        { id: 4, picture: Slider94, alt: "slider-4",  },
        { id: 5, picture: Slider95, alt: "slider-5",  },
        { id: 3, picture: TEAVILLAM06, alt: "slider-3",  },
        { id: 4, picture: TEAVILLAM07, alt: "slider-4",  },
        { id: 5, picture: TEAVILLAM08, alt: "slider-5",  }
      ],
    }
  },
  {
    id: 12, picture: Interiors20,
    url: "/interiors-detail/12",
    text: "TeaVilla Cafe, Hyderabad",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 12,
      mainHeading: "TeaVilla Cafe, Hyderabad",
      cHeading: "HISTORIC OPULENCE",
      watchIcon:"", cPicture1: C20,
      cText1: "‘To the glory of the Nizams’",
	  content: "Sweet, sumptuous fables of Baghdad</br> The splendours of your court recall,</br> The torches of a Thousand Nights</br> Blaze through a single festival;</br> And Saki-singers down the streets,</br> Pour for us, in a stream divine, </br>From goblets of your love-ghazals </br>The rapture of your Sufi wine.",
      subContent1: "Lavish, opulent, ostentatious, and extravagant are just some words that define the ambience of Tea Villa cafe, Hyderabad. An ode to an era past, this design was born through the desire to relive the grandeur and opulence from the pages of history.",
      subContent2: "A 'larger than life' seating nook is inspired by the monarchical period in the city of nizams. Colossal arches of windows and the richly crafted upholstery add to the undeniable palatial interiors that evoke a sense of regal grandeur while having a splendid leisure at this cafe.",
      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section58, alt: "slider-1",  },
        { id: 2, picture: Section59, alt: "slider-2",  },
        { id: 3, picture: Section60, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider96, alt: "slider-1",  },
        { id: 2, picture: Slider97, alt: "slider-2",  },
        { id: 3, picture: Slider98, alt: "slider-3",  },
        { id: 4, picture: Slider99, alt: "slider-4",  },
        { id: 5, picture: Slider100, alt: "slider-5",  },
        { id: 3, picture: TEAVILLAH06, alt: "slider-3",  },
        { id: 4, picture: TEAVILLAH07, alt: "slider-4",  },
        { id: 5, picture: TEAVILLAH08, alt: "slider-5",  }
      ],
    }
  },
 {
    id: 13, picture: Interiors10,
    url: "/interiors-detail/13",
    text: "Savage, Juhu",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 13,
      mainHeading: "Savage, Juhu",
      type: INTERIOR_TYPE_CONST.COMMERCIAL,
      cHeading: "THE PICTURESQUE PIAZZA",
      watchIcon:"", cPicture1: C10,
      cText1: "‘Reminiscent of Gothic-inspired structures’",
      content: "The entire venue is a collage of textures, materiality & thematic design that tells a story of its own. Street elements, pieces in wrought iron, raw exposed stone, metal & pebbled surfaces layer the entire space with an added texture. The outdoors blend into the indoor section where the ambience is reminiscent of Gothic-inspired structures. The lighting in the entire project plays a crucial role in many forms like ambient lighting, indirect lighting, cove lighting & wall washers. The facade is a parametric metal envelope that wraps the elevation in its lattice form, giving the restro-lounge a striking persona.",
      subContent1: "Savage, juhu is the embodiment of suave elements meshed in together to create a moody & high-energy venue. This restrobar was designed & executed over the period of march to june 2019. The venue spans over an area of 2000 sq. Ft indoors, 6000 sq. Ft outdoors & 2000 sq. Ft on the terrace level. The client's preference was centred on using raw & masculine design elements that came together to create a chic setup.",
      subContent2: "The design firm chose to go with a focus on visualizing the entire space as a European Piazza which historically was public squares & marketplaces. The outdoor section is of pivotal functional & spatial importance in the layout. This section can also organically transform into an outdoor performance area that can accommodate up to 500 guests at a time.",
      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section28, alt: "slider-1",  },
        { id: 2, picture: Section29, alt: "slider-2",  },
        { id: 3, picture: Section30, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider46, alt: "slider-1",  },
        { id: 2, picture: Slider47, alt: "slider-2",  },
        { id: 3, picture: Slider48, alt: "slider-3",  },
        { id: 4, picture: Slider49, alt: "slider-4",  },
        { id: 5, picture: Slider50, alt: "slider-5",  },
        { id: 6, picture: savage06, alt: "slider-3",  },
        { id: 7, picture: savage07, alt: "slider-4",  },
        { id: 8, picture: savage08, alt: "slider-5",  }
      ],
    }
  },
	{
    id: 14, picture: Interiors3, url: "/interiors-detail/14", text: "Hex",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 14,
      mainHeading: "Hex",
      cHeading: "AN IMMERSIVE WONDER",
      watchIcon:Hex, cPicture1: C3,
      cText1: "‘A Design from the future.’",
      content: "With a massive play of LED lights in different forms, the entire design of this high-energy resto-bar feels like a galactic illusion. The continuous strips of blue LED light in the entrance perfectly create the experience of entering through the airlock of a spacecraft. The abstract web-like LED strips in the main bar area further accentuate the design of this space, creating the experience of 'A design from the future’",
      subContent1: "The interior concept of this restaurant-bar centres around a futuristic outer space theme thoughtfully designed to take the guests on an immersive adventure into the wonders of the universe.",
      subContent2: "From the sci-fi entrance, edgy-looking main arena, and futuristic bar to a glowing neon light roof designed in a web-like structure, every element of the design matches the theme to bring you a high energy and out-of-this-world experience.",

      video: Video3,
      middleSectionPictures: [
        { id: 1, picture: Section7, alt: "slider-1",  },
        { id: 2, picture: Section8, alt: "slider-2",  },
        { id: 3, picture: Section9, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider11, alt: "slider-1",  },
        { id: 2, picture: Slider12, alt: "slider-2",  },
        { id: 3, picture: Slider13, alt: "slider-3",  },
        { id: 4, picture: Slider14, alt: "slider-4",  },
        { id: 5, picture: Slider15, alt: "slider-5",  },
        { id: 6, picture: HEX06, alt: "slider-3",  },
        { id: 7, picture: HEX07, alt: "slider-4",  },
        { id: 8, picture: HEX08, alt: "slider-5",  }
      ],
    }
  },  
  {
    id: 15, picture: Interiors5, url: "/interiors-detail/15", text: "Master Chef Lounge",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 15,
      mainHeading: "Master Chef Lounge",
      cHeading: "ABSOLUTELY RUSTIC CHIC",
      watchIcon:"", cPicture1: C5,
      cText1: "‘A vivid verdant green.’",
      content: "Brilliant green color shades look gorgeous with modern brown colors, adding the quotient of sophistication. From dark gray color tones to striking platinum and elegant light gray, this vivid emerald creates a natural, soft, and airy interior design color scheme for the lounge.",
      subContent1: "An intimate 500 sqft bar size with 14 ft. Ceiling ht, giving the liberty to play with parametric topography using hollow pipes.",
      subContent2: "The use of natural materials and an earthy palette for the fittings and fixtures results in a relaxed dining atmosphere.",

      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section13, alt: "slider-1",  },
        { id: 2, picture: Section14, alt: "slider-2",  },
        { id: 3, picture: Section15, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider21, alt: "slider-1",  },
        { id: 2, picture: Slider22, alt: "slider-2",  },
        { id: 3, picture: Slider23, alt: "slider-3",  },
        { id: 4, picture: Slider24, alt: "slider-4",  },
        { id: 5, picture: Slider25, alt: "slider-5",  },
        { id: 5, picture: MASTER06, alt: "slider-5",  }
      ],
    }
  },
  {
    id: 16,
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    picture: Interiors2,
    url: "/interiors-detail/16",
    text: 'Beirut, Versova',
    detail: {
      mainHeading: "Beirut, Versova",
      id: 16,
      cHeading: "A DESIGN PARADOX",
      watchIcon:Beirut, cPicture1: C2,
      cText1: "‘for a king of the old glory days’",
      content: "I am from Lebanon, from Beirut.</br>I am from the ground underneath my home</br>I am from the trees, the cedar tree</br>I come from Tabouleh and brown eyes, from Karim...</br>Kassar and Kassem</br>I come from happiness and culture</br>From 'Habibi' and 'Hayete'</br>I am from all religions</br>I am from the room beneath the stars.'</br>― Zeina Kassem, Talal Kassem",
      subContent1: "Luxury amidst the ruins of lebanon. 'BEIRUT' is a beautifully crafted design paradox where the old palatial charm battles with the ruinous state of lebanon. It symbolises the harmony and the love that exists in Lebanon despite the turmoil and the instability.",
      subContent2: "The bar is fit for a king of the old glory days. Its walls are adorned with gold and beige monotones to blend the old with the new. The ceiling is fitted with clay tiles and vibrant coloured glass playfully entwined to add to the dramatic themes of the space.</br>Come to BEIRUT to feel an empire, which offers dust and sand but also gold and nobility.",

      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section4, alt: "slider-1",  },
        { id: 2, picture: Section5, alt: "slider-2",  },
        { id: 3, picture: Section6, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider6, alt: "slider-1",  },
        { id: 2, picture: Slider7, alt: "slider-2",  },
        { id: 3, picture: Slider8, alt: "slider-3",  },
        { id: 4, picture: Slider9, alt: "slider-4",  },
        { id: 5, picture: Slider10, alt: "slider-5",  },
        { id: 5, picture: BEIRUT06, alt: "slider-5",  }
      ],
    }
  },
  
  {
    id: 17, picture: Interiors6,
    url: "/interiors-detail/17",
    text: "FISCO RestoBar",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 17,
      mainHeading: "FISCO RestoBar",
      cHeading: "INDUSTRIAL AESTHETICS",
      watchIcon:"", cPicture1: C6,
      cText1: "‘The raw attractiveness.’",
      content: "The industrial aesthetic is masculine but poignant, fuss-free but thoughtful. The raw magic of the industrial design evinces that the finer things in life aren’t always the most frivolous or flashy.",
      subContent1: "Beautifully orchestrated raw materials - Bricks and Metal create a spacious feel with plenty of character bringing an edginess and rawness to the space. The simplicity of bricks and ductility of metal is played with a twist to create an atmosphere which is unpretentious and edgy with a lot of thought going into every little detail.",
      subContent2: "A style that glorifies function over form by embracing high ceilings, dangling metal lighting fixtures, and earthy tones adding warmth to the space.",

      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section16, alt: "slider-1",  },
        { id: 2, picture: Section17, alt: "slider-2",  },
        { id: 3, picture: Section18, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider26, alt: "slider-1",  },
        { id: 2, picture: Slider27, alt: "slider-2",  },
        { id: 3, picture: Slider28, alt: "slider-3",  },
        { id: 4, picture: Slider29, alt: "slider-4",  },
        { id: 5, picture: Slider30, alt: "slider-5",  },
        { id: 6, picture: FISCO06, alt: "slider-3",  },
        { id: 7, picture: FISCO07, alt: "slider-4",  },
        { id: 8, picture: FISCO08, alt: "slider-5",  }
      ],
    }
  },
   {
    id: 18, picture: Interiors15, url: "/interiors-detail/18",
    text: "The Food Studio, Malad",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 18,
      mainHeading: "The Food Studio, Malad",
      cHeading: "ECLECTIC PARADIGM",
      watchIcon:"", cPicture1: C15,
      cText1: "‘Unconventional Dining Ambience.’",
      content: "The interior scheme is a visual symphony of turquoise blue & copper, interjected by artificial greens to break the overruling texture of the space. Subtle additions of wood add a umber warmth to the volume & bind the space together. An intriguing coalition of hues, textures & patterns renders the food studio its sense of identity. A space that has a bold design persona & an inviting presence in its context. Imagine looking up to find a layered collage of materials & textures that tell a story of their own. It's about creating something that comes across as a playful blend of elements, unafraid to be unconventional & its own kind of beautiful.",
      subContent1: "The Food Studio, Malad designed by the house of ColabCompany has been conceived at an intrinsic level to cater to individuals across all age brackets. This multi cuisine fine dining venue completed in May 2019, is a delight to the visual & culinary palettes alike. The design vibe of the restaurant is an amalgamation of eclectic & casual family-friendly dining ambience.",
      subContent2: "A variety of seating arrangements ranging from cosy 2-seater table units, to long-running sofas to accommodate a larger group of guests & a private dining room for 30 + guests makes the venue a versatile one in terms of its hosting capacity as per requirement.</br> Spanning over an area of 2600 square feet, the spatial design of the venue was envisioned as an organised spread of spaces that adheres to a systematic orientation, which has been punctuated with distorted grids on the ceiling surface, composed of a collage of circular discs strewn across varying heights.",
      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section43, alt: "slider-1",  },
        { id: 2, picture: Section44, alt: "slider-2",  },
        { id: 3, picture: Section45, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider71, alt: "slider-1",  },
        { id: 2, picture: Slider72, alt: "slider-2",  },
        { id: 3, picture: Slider73, alt: "slider-3",  },
        { id: 4, picture: Slider74, alt: "slider-4",  },
        { id: 5, picture: Slider75, alt: "slider-5",  },
        { id: 6, picture: food06, alt: "slider-3",  },
        { id: 7, picture: food07, alt: "slider-4",  },
        { id: 8, picture: food08, alt: "slider-5",  }
      ],
    }
  },
  {
    id: 19, picture: Interiors7,
    url: "/interiors-detail/19",
    text: "Tunak Tunak Tunn",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 19,
      mainHeading: "Tunak Tunak Tunn",
      cHeading: "TRADITIONAL FLAIRS",
      watchIcon:"", cPicture1: C7,
      cText1: "‘Sensorial gourmet experiences of Punjab.’",
      content: "Tune into Tunak to encounter an episode full of homely vibe, imperishable interiors and bruahhh...food.",
      subContent1: "Tunak tunak tunn is a space complemented by the fusion of rustic yet vibrant and radiant culture of punjab. The aspirational environment is alluring in every aspect.",
      subContent2: "The ceiling speaks ikat patterns with a combination of mirrors to adorn the time-honoured floral phulkari with modern mold. The terrace and the outer zone witness the true colours of punjab with its vibrancy. Bar is the reflecting window of the coruscating facade appraising their eclectic melange.",

      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section19, alt: "slider-1",  },
        { id: 2, picture: Section20, alt: "slider-2",  },
        { id: 3, picture: Section21, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider31, alt: "slider-1",  },
        { id: 2, picture: Slider32, alt: "slider-2",  },
        { id: 3, picture: Slider33, alt: "slider-3",  },
        { id: 4, picture: Slider34, alt: "slider-4",  },
        { id: 5, picture: Slider35, alt: "slider-5",  },
        { id: 6, picture: TUNAK06, alt: "slider-3",  },
        { id: 7, picture: TUNAK07, alt: "slider-4",  },
        { id: 8, picture: TUNAK08, alt: "slider-5",  }
      ],
    }
  },
    {
    id: 20, picture: Interiors4, url: "/interiors-detail/20", text: "BallRoom by BCB",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 20,
      mainHeading: "BallRoom by BCB",

      cHeading: "ECHOES OF ROME",
      watchIcon:"", cPicture1: C4,
      cText1: "‘Imperial beauty of aesthetics.’",
      content: "The rich, lush, sophisticated design blended all the charm of Ancient Rome.",
      subContent1: "An 8000 sq ft. Banquet, designed as a museum with roman influences, flaunting the dramatic and contrasting contemporary ceiling as a focal art piece.",
      subContent2: "The atmosphere of luxury and expressiveness in this design direction is combine with nobility to add pleasantness and aesthetics to the reception of guests. The sophistication of the motifs gives the room comfort, chicness, and presentability.",

      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section10, alt: "slider-1",  },
        { id: 2, picture: Section11, alt: "slider-2",  },
        { id: 3, picture: Section12, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider16, alt: "slider-1",  },
        { id: 2, picture: Slider17, alt: "slider-2",  },
        { id: 3, picture: Slider18, alt: "slider-3",  },
        { id: 4, picture: Slider19, alt: "slider-4",  },
        { id: 5, picture: Slider20, alt: "slider-5",  },
        { id: 6, picture: BCB06, alt: "slider-4",  },
        { id: 7, picture: BCB07, alt: "slider-5",  }
      ],
    }
  },
  
  
  {
    id: 21, picture: Interiors18, url: "/interiors-detail/21",
    text: "TIMES SQUARE",
    type: INTERIOR_TYPE_CONST.HOSPITALITY,
    detail: {
      id: 21,
      mainHeading: "TIMES SQUARE",
      cHeading: "BARE NATURE OF INDUSTRIAL ERA",
      watchIcon:"", cPicture1: C18,
      cText1: "‘ A turn of the century vibe. ’",
      content: "One of the key elements of our design was the subtle balance between utilitarian and comfy, giving off a turn of the century vibe, where utility and comfort walks hand in hand.",
      subContent1: "Simplicity of bricks and ductility of metal played here with a twist to create beautifully orchestratedinterior design that is perfect for cultivating an austere and comfortable ambiance.",
      subContent2: "It is a subtle marriage of comfort and modernity, and it utilises both metallic and organic elements. A timeless yet characterful design that aligns with the sensibilities of the contemporary customer.",

      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section52, alt: "slider-1",  },
        { id: 2, picture: Section53, alt: "slider-2",  },
        { id: 3, picture: Section54, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider86, alt: "slider-1",  },
        { id: 2, picture: Slider87, alt: "slider-2",  },
        { id: 3, picture: Slider88, alt: "slider-3",  },
        { id: 4, picture: Slider89, alt: "slider-4",  },
        { id: 5, picture: Slider90, alt: "slider-5",  }
      ],
    }
  },
 
  
  
  {
    id: 22, picture: Interiors21,
    url: "/interiors-detail/22",
    text: "Avant Garde Project, Mumbai",
    type: INTERIOR_TYPE_CONST.COMMERCIAL,
    detail: {
      id: 22,
      mainHeading: "Avant Garde Project, Mumbai",
      cHeading: "OH-SO-WOW WORKSPACE",
      watchIcon:Avantgarde, cPicture1: AVANT_C,
	  cText1: "‘A living work of art.’",
      content: "With a fresco-inspired ceiling wallpaper, a pseudo-silk rug, grooved walls and a modest yet warm seating corner, our futuristic cabin is a living work of art. A bright blue portrait bust complements the bold blue-powder-coated perforated sheet, encasing three tubelights, adding character to the space effortlessly. An elegant table to work on, a space to brainstorm in, a corner to unwind at and more -- we have it all, redefining the 21st century workspace experience.",
      subContent1: "We came, we created, we conquered. A futuristic, eclectic and oh-so-wow workspace that intricately amalgamates different elements from the global design scape into one.",
      subContent2: "Opening doors to thought-provoking conversations, our workspace has an inviting conference room with a corian top and a base that resembles the silhouette of a cloud, encased in silver film. Yes, we are on cloud nine!",
      video: Video22,
      middleSectionPictures: [
        { id: 1, picture: Section64, alt: "slider-1",  },
        { id: 2, picture: Section65, alt: "slider-2",  },
        { id: 3, picture: Section66, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider107, alt: "slider-1",  },
        { id: 2, picture: Slider108, alt: "slider-2",  },
        { id: 3, picture: Slider109, alt: "slider-3",  },
        { id: 4, picture: Slider110, alt: "slider-4",  },
        { id: 5, picture: Slider111, alt: "slider-5",  },
		{ id: 6, picture: Slider112, alt: "slider-3",  },
        { id: 7, picture: Slider113, alt: "slider-4",  },
        { id: 8, picture: Slider114, alt: "slider-5",  }
      ],
    }
  },
   {
    id: 23, picture: Interiors20,
    url: "/interiors-detail/23",
    text: "TeaVilla Corporate Office, Kanakia Wallstreet",
    type: INTERIOR_TYPE_CONST.COMMERCIAL,
    detail: {
      id: 23,
      mainHeading: "TeaVilla Corporate Office, Kanakia Wallstreet",
      cHeading: "BOLD CONTRASTS",
      watchIcon:"", cPicture1: C26,
      cText1: "‘A regal workspace.’",
      content: "The cafe echoes a contrast to create a personal space where one can relax and get a certain warmth. The director's cabin has been carved out to make an elegant power house. It has a clear view of the entire office and at the same time privacy can be maintained.",
      subContent1: "Chic and elegant, the new headquarters for Tea Villa Cafe, sets a new standard in office design. Bold furniture highlights the soft colours in the spacious rooms.",
      subContent2: "The coffered vintage ceiling imbibes a sense of authority and gives a regal aura to the space. The conference room is elegant, classy, and infused with all the modern tech one needs.",

      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section147, alt: "slider-1",  },
        { id: 2, picture: Section148, alt: "slider-2",  },
        { id: 3, picture: Section149, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider139, alt: "slider-1",  },
        { id: 2, picture: Slider140, alt: "slider-2",  },
        { id: 3, picture: Slider141, alt: "slider-3",  },
        { id: 4, picture: Slider142, alt: "slider-4",  },
        { id: 5, picture: Slider143, alt: "slider-5",  },
		{ id: 3, picture: Slider144, alt: "slider-3",  },
        { id: 4, picture: Slider145, alt: "slider-4",  },
        { id: 5, picture: Slider146, alt: "slider-5",  }
      ],
    }
  }, 
  {
    id: 24, picture: Interiors23,
    url: "/interiors-detail/24",
    text: "ETHER Atelier, Mumbai",
    type: INTERIOR_TYPE_CONST.COMMERCIAL,
    detail: {
      id: 24,
      mainHeading: "ETHER Atelier, Mumbai",
      cHeading: "MODERN MINIMALISM",
      watchIcon:"", cPicture1: C23,
      cText1: "‘Pierre Jeanneret’s panache.’",
	  content: "Conceived and executed over a stringent timeline of merely two months, this office space celebrates modern minimalism, marrying raw materiality with pragmatic design. The space is concocted by employing bare finishes that shun superfluous ornamentation. The visual palette here is headlined by concrete, marble, sleek Corian surfaces, corten steel, black metal, glass, and umber tones. The spatial layout at the studio has been conceptualised as 4 main zones that host various functions, yet are cohesively tethered via design interventions; this has been executed without the introduction of physical partition systems, still enabling an uninterrupted functional flow amidst areas.",
      subContent1: "Ether Atelier’s office embraces minimalism and a rooted design ethos with its every weave in Mumbai. The studio is doused in a timeless modernist charm that entwines seamlessly with industrial undertones.",
      subContent2: "Chef Bhaktiani’s Atelier has reimagined the timeless desert and has given it a facelift in terms of its identity, presenting it in an ingenious light to the contemporary patron. The ethos of the company finds its roots embedded in subtlety laced with keen attention to detail; their backend office studio needed to be reflective of the same.",
      

      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section67, alt: "slider-1",  },
        { id: 2, picture: Section68, alt: "slider-2",  },
        { id: 3, picture: Section69, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider115, alt: "slider-1",  },
        { id: 2, picture: Slider116, alt: "slider-2",  },
        { id: 3, picture: Slider117, alt: "slider-3",  },
        { id: 4, picture: Slider118, alt: "slider-4",  },
        { id: 5, picture: Slider119, alt: "slider-5",  },
		{ id: 6, picture: Slider120, alt: "slider-3",  },
        { id: 7, picture: Slider121, alt: "slider-4",  },
        { id: 8, picture: Slider122, alt: "slider-5",  }
      ],
    }
  },
  {
    id: 25, picture: Interiors22,
    url: "/interiors-detail/25",
    text: "Golden Lotto",
    type: INTERIOR_TYPE_CONST.COMMERCIAL,
    detail: {
      id: 25,
      mainHeading: "Golden Lotto",
      cHeading: "A PLAYFUL INTERACTIVE.",
      watchIcon:Goldenlotto, cPicture1: C24,
      cText1: "‘A celebration of glorious victories.’",
      content: "The whole vibe of the office is all about the celebration of victory of its players.",
      subContent1: "This office space was designed for India’s first and most reliable Lotto brand- Golden Lotto. For this space, a lot of inspiration was taken from the game play and branding.",
      subContent2: "The walls and flooring were left bare with a pop of brand colours added through an installation on an art piece.While the play of light emphases on the motion of the balls in the pot.",

      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section70, alt: "slider-1",  },
        { id: 2, picture: Section71, alt: "slider-2",  },
        { id: 3, picture: Section72, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider123, alt: "slider-1",  },
        { id: 2, picture: Slider124, alt: "slider-2",  },
        { id: 3, picture: Slider125, alt: "slider-3",  },
        { id: 4, picture: Slider126, alt: "slider-4",  },
        { id: 5, picture: Slider127, alt: "slider-5",  },
		{ id: 6, picture: Slider128, alt: "slider-3",  },
        { id: 7, picture: Slider129, alt: "slider-4",  },
        { id: 8, picture: Slider130, alt: "slider-5",  }
      ],
    }
  }, 
  {
    id: 26, picture: Interiors25,
    url: "/interiors-detail/26",
    text: "Jalaram Office",
    type: INTERIOR_TYPE_CONST.COMMERCIAL,
    detail: {
      id: 26,
      mainHeading: "Jalaram Office",
      cHeading: "‘Spruce up the workplace.’",
      watchIcon:"", cPicture1: C25,
      cText1: "‘for a king of the old glory days’",
      content: "Decorating the walls with pastel green wallpapers, it creates a peaceful and pleasant spot for human eyes to rest and make the office interior feel peaceful and healthy all the while keeping the office environment distinctively spruced up.",
      subContent1: "This office defines a family office. Each of the two sons' distinct personalities is reflected in their own personal space whereas the meeting room and common spaces seamlessly are designed such that they bind the distinct personalities together.",
      subContent2: "That in mind, a beautiful, visually-aligned and inspiring office was designed with brilliant hues of green colour along with modern brown colours, bringing a fresh and exciting perspective to the modern office.",

      video: "",
      middleSectionPictures: [
        { id: 1, picture: Section73, alt: "slider-1",  },
        { id: 2, picture: Section74, alt: "slider-2",  },
        { id: 3, picture: Section75, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider131, alt: "slider-1",  },
        { id: 2, picture: Slider132, alt: "slider-2",  },
        { id: 3, picture: Slider133, alt: "slider-3",  },
        { id: 4, picture: Slider134, alt: "slider-4",  },
        { id: 5, picture: Slider135, alt: "slider-5",  },
		{ id: 6, picture: Slider136, alt: "slider-1",  },
        { id: 7, picture: Slider137, alt: "slider-2",  },
        { id: 8, picture: Slider138, alt: "slider-3",  }
      ],
    }
  },
  {
    id: 27, picture: DIGIENTCHAPTERBEG,
    url: "/interiors-detail/27",
    text: "Digient Technologies, Chennai </br>[The Beginning]",
    type: INTERIOR_TYPE_CONST.COMMERCIAL,
    detail: {
      id: 27,
      mainHeading: "Digient Technologies, Chennai [The Beginning]",
      cHeading: "'A UNIQUE FUSION'",
      watchIcon:"", cPicture1: DB_C,
      cText1: "‘agnificent Hues.’",
      content: "A workplace is the fountainhead of creativity, where ideas translate into tangible projects. This is why at Colab Company, we believe that workplaces should inspire and fuel the people that work in them, providing distinct spaces for all kinds of tasks and to experience an unparalleled workspace vibe through interesting colour hues.",
      subContent1: "A 35,000 sqft of office space spread across five floors and a terrace with a fusion of contemporary and eclectic styles.The first step inside the building introduces one to the coastal-inspired ambience of the ground floor with muted tints and shades of brown.",
      subContent2: "A bold and elegant 3D artwork made with real shells, corals and sand, complements the curvilinear furniture, transporting one to a fun littoral destination. The locker room, with lockers painted in hues of red and yellow add a vibrant stroke, seeking inspiration from the magnificent colours of the sunrise and sunset.",

      video: "",
      middleSectionPictures: [
        { id: 1, picture: DB5, alt: "slider-1",  },
        { id: 2, picture: DB6, alt: "slider-2",  },
        { id: 3, picture: DB7, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: DB1, alt: "slider-1",  },
        { id: 2, picture: DB2, alt: "slider-2",  },
        { id: 3, picture: DB3, alt: "slider-3",  },
        { id: 4, picture: DB4, alt: "slider-4",  }
      ],
    }
  },
 {
    id: 28, picture: DIGIENTCHAPTER1,
    url: "/interiors-detail/28",
    text: "Digient Technologies, Chennai [Chapter I]",
    type: INTERIOR_TYPE_CONST.COMMERCIAL,
    detail: {
      id: 28,
      mainHeading: "Digient Technologies, Chennai [Chapter I]",
      cHeading: "'A NEW-AGE OFFICE'",
      watchIcon:CH1, cPicture1: CDigient,
      cText1: "‘A maximalist yet contemporary style.’’",
      content: "Floor 1</br>an unparalleled workspace vibe through interesting nooks and corners, created for brainstorming, focused working, and creative sessions in a maximalist yet contemporary style. Adorned with vibrant green plants, curvilinear, thoughtfully-crafted furniture, and walls done through masterful colour blocking, this modern floor is perfect to get one in the highly-productive zone of working with an undistracting focus.",
      subContent1: "A fusion of contemporary and eclectic styles has been at the core of our design strategy for the new-age office space for digient technologies in chennai where we designed 35,000 sqft of office space spread across five floors and a terrace.",
      subContent2: "We believe that workplaces should inspire and fuel the people that work in them, providing distinct spaces for all kinds of tasks, and not one replicated design for everyone and everything. The first step inside the building introduces one to the coastal-inspired ambience of the ground floor with muted tints and shades of brown. A bold and elegant 3d artwork made with real shells, corals, and sand, complements the curvilinear furniture, transporting one to a fun littoral destination.",

      video: Digient,
      middleSectionPictures: [
        { id: 1, picture: D_07, alt: "slider-1",  },
        { id: 2, picture: D_08, alt: "slider-2",  },
        { id: 3, picture: D_09, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Digient01, alt: "slider-1",  },
        { id: 2, picture: Digient02, alt: "slider-2",  },
        { id: 3, picture: Digient03, alt: "slider-3",  },
        { id: 4, picture: Digient04, alt: "slider-4",  },
        { id: 5, picture: Digient05, alt: "slider-5",  },
		{ id: 6, picture: Digient06, alt: "slider-3",  }
      ],
    }
  },
    {
    id: 29, picture: DIGIENTCHAPTER2,
    url: "/interiors-detail/29",
    text: "Digient Technologies, Chennai [Chapter II]",
    type: INTERIOR_TYPE_CONST.COMMERCIAL,
    detail: {
      id: 29,
      mainHeading: "Digient Technologies, Chennai [Chapter II]",
      cHeading: "'EXQUISITE SYMMETRY'",
      watchIcon:CH2, cPicture1: CDi,
      cText1: "‘Changing the definition of office spaces in India.’",
      content: "FLOOR 2</br> Floor two of Digient Technologies stands out for bold jardin-style design patterns with a splash of symmetry done up in an earthy palette. With benches and lamp posts that stimulate ideas along with giving you the feeling of being in a park inside a stunning glasshouse, the floor's spaces are perfect to boost productivity, swiftly walking away from the mundane.",
      subContent1: "A space where you don’t just enjoy working but will also fall in love with your craft again. A modernist yet eclectic design that syncs with its environs, creating a stunning, new visual and psychological sense of space.",
      subContent2: "Because, of course, we are all unique! And hence we believe that workplaces should inspire and fuel the people that work in them, providing distinct spaces for all kinds of tasks, and not one replicated design for everyone and everything.",

      video: Digient,
      middleSectionPictures: [
        { id: 1, picture: Di08, alt: "slider-1",  },
        { id: 2, picture: Di09, alt: "slider-2",  },
        { id: 3, picture: Di10, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Di01, alt: "slider-1",  },
        { id: 2, picture: Di02, alt: "slider-2",  },
        { id: 3, picture: Di03, alt: "slider-3",  },
        { id: 4, picture: Di04, alt: "slider-4",  },
        { id: 5, picture: Di05, alt: "slider-5",  },
		{ id: 6, picture: Di06, alt: "slider-3",  },
        { id: 7, picture: Di07, alt: "slider-4",  }
      ],
    }
  },
    {
    id: 30, picture: DIGIENTCHAPTER3,
    url: "/interiors-detail/30",
    text: "Digient Technologies, Chennai [Chapter III]",
    type: INTERIOR_TYPE_CONST.COMMERCIAL,
    detail: {
      id: 30,
      mainHeading: "Digient Technologies, Chennai [Chapter III]",
      cHeading: "'EUREKA!’ EFFECT'",
      watchIcon:CH3, cPicture1: D_C,
      cText1: "‘Effortlessly Thoughtful And Zen.’",
      content: "Floor 3</br> is a subtle nod to flowy simplicity. The jute-knitted chairs, the tall bamboo plants with ivory, gray, and violet seating options are effortlessly crafted in the style of refined materiality, verticality, and repetition.",
      subContent1: "A faint reminder of Japan and a floor that exudes Zen, the spaces are a sight for sore eyes and ergonomic to sit and work on for long hours.",
      subContent2: "A thoughtful use of ceiling and floor lamps, on a floor flooded with natural light, illuminates it even further into a bright space, ideal for multiple ‘Eureka’ moments.",

      video: Digient,
      middleSectionPictures: [
        { id: 1, picture: D07, alt: "slider-1",  },
        { id: 2, picture: D08, alt: "slider-2",  },
        { id: 3, picture: D09, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: D01, alt: "slider-1",  },
        { id: 2, picture: D02, alt: "slider-2",  },
        { id: 3, picture: D03, alt: "slider-3",  },
        { id: 4, picture: D04, alt: "slider-4",  },
        { id: 5, picture: D05, alt: "slider-5",  },
		{ id: 6, picture: D06, alt: "slider-3",  },
      ],
    }
  },
    {
    id: 31, picture: DIGIENTCHAPTER4,
    url: "/interiors-detail/31",
    text: "Digient Technologies, Chennai [Chapter IV]",
    type: INTERIOR_TYPE_CONST.COMMERCIAL,
    detail: {
      id: 31,
      mainHeading: "Digient Technologies, Chennai [Chapter IV]",
      cHeading: "'SUI GENERIS'",
      watchIcon:CH4, cPicture1: D4_C,
      cText1: "‘A metaphor for futuristic space.’",
      content: "As we climb to the top floor, we see that it is a metaphor for futuristic design. The modern composition is to give an impetus to a no-nonsense attitude towards work and to the creation of bright ideas that shape the times to come. For a breath of fresh air, after a long meeting, the terrace opens up to the sky and exudes a soho garden vibe. It also has canopies to laze under at any given time of the day.",
      subContent1: "With a fusion of contemporary and eclectic styles, this floor was skillfully done in monotone creating a style that is sui generis.",
      subContent2: "This creation by the Colab Company is where you don’t just enjoy working but also fall in love with your craft again. The motivating quotes in different areas of the space are the final cherry on the top – changing the definition of office spaces in India.",

      video: Digient,
      middleSectionPictures: [
        { id: 1, picture: Di7, alt: "slider-1",  },
        { id: 2, picture: Di8, alt: "slider-2",  },
        { id: 3, picture: Di9, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Di1, alt: "slider-1",  },
        { id: 2, picture: Di2, alt: "slider-2",  },
        { id: 3, picture: Di3, alt: "slider-3",  },
        { id: 4, picture: Di4, alt: "slider-4",  },
        { id: 5, picture: Di5, alt: "slider-5",  },
		{ id: 6, picture: Di6, alt: "slider-3",  }
      ],
    }
  },
  {
    id: 32, picture: Interiors26,
    url: "/interiors-detail/32",
    text: "Casa Neue",
    type: INTERIOR_TYPE_CONST.RESIDENTIAL,
    detail: {
      id: 32,
      mainHeading: "Casa Neue",
      cHeading: "'A juxtaposition of classic, modern, raw and luxurious.'",
      watchIcon:Casaneue, cPicture1: C27,
      cText1: "‘EMBLEMATIC OF THE INGENIOUS.’",
      content: "The entire process was a trance state of ‘ikigai’ for the lead designers - a feeling of finding satiation in being occupied and working on their quintessential ‘home’. What inspired the designers in an ardent manner was taking inspiration from creations completely out of the realms of their craft, which brought a fresh perspective to the table - reiterating the fact that design lies at the node of curiosity and the will to explore uncharted territories.",
      subContent1: "Casa Neue is emblematic of the ‘ingenious’ - a home pieced together with its every weave by the design partners with utmost affection and prowess. This abode was conceptualised from the get-go as a blank canvas that encapsulates every facet of design the Architects and homeowners in this case would resonate with.",
      subContent2: "Casa Neue is a physical manifestation of the shared aspirations of the Architects, creating a stage for visual and tangible spatial experiences to come to life. As creators one is often submitted to the utilities of the end-user, but in order to envisage something anew one has to take chances - conviction, an uninhibited imagination and a focused vision are the essence of this abode.",

      video: Video27,
      middleSectionPictures: [
        { id: 1, picture: Section79, alt: "slider-1",  },
        { id: 2, picture: Section80, alt: "slider-2",  },
        { id: 3, picture: Section81, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider150, alt: "slider-1",  },
        { id: 2, picture: Slider151, alt: "slider-2",  },
        { id: 3, picture: Slider152, alt: "slider-3",  },
        { id: 4, picture: Slider153, alt: "slider-4",  },
        { id: 5, picture: Slider154, alt: "slider-5",  },
		{ id: 6, picture: Slider155, alt: "slider-3",  },
        { id: 7, picture: Slider156, alt: "slider-4",  },
        { id: 8, picture: Slider157, alt: "slider-5",  }
      ],
    }
  }, 
  {
    id: 33, picture: Interiors30,
    url: "/interiors-detail/33",
    text: "Abode",
    type: INTERIOR_TYPE_CONST.RESIDENTIAL,
    detail: {
      id: 33,
      mainHeading: "Abode",
      cHeading: "'URBAN MODERNIST'",
      watchIcon:ABODEWATCH, cPicture1: ABODEC,
      cText1: "‘EMBLEMATIC OF THE INGENIOUS.’",
      content: "The residence is a composition of five private bedrooms that each is a seamless projection of the inhabitant’s personality, capacious kitchen, shared family spaces, areas to entertain and doses of the outdoors in the form of decks - the design scheme ensures that there is a continuous dialogue amidst spaces while they anchor diverse functions.",
      subContent1: "An Architect-Duo’s residence sprawling over 4,200 sq ft on the fringes of Mumbai; replete with an interplay of colour, shifting silhouettes and an unapologetically expressive scheme of design.",
      subContent2: "Maximalism runs in the very pulse of this abode - in a way this home is a reflection of the designer’s deep-rooted resonance with eclecticism - a juxtaposition of classic, modern, raw and luxurious strains of design and the creation of à la mode spaces.",

      video: ABODEVIDEO,
      middleSectionPictures: [
        { id: 1, picture: ABODE7, alt: "slider-1",  },
		{ id: 2, picture: ABODE8, alt: "slider-1",  },
        { id: 3, picture: ABODE9, alt: "slider-2",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: ABODE1, alt: "slider-1",  },
        { id: 2, picture: ABODE2, alt: "slider-2",  },
        { id: 3, picture: ABODE3, alt: "slider-3",  },
        { id: 4, picture: ABODE4, alt: "slider-4",  },
        { id: 5, picture: ABODE5, alt: "slider-5",  },
		{ id: 6, picture: ABODE6, alt: "slider-3",  }
      ],
    }
  },
  {
    id: 34, picture: Interiors27,
    url: "/interiors-detail/34",
    text: "Orangutan Esports Facility",
    type: INTERIOR_TYPE_CONST.INSTITUTIONAL,
    detail: {
      id: 34,
      mainHeading: "Orangutan Esports Facility",
      cHeading: "'WORK & PLAY'",
      watchIcon:Orangutan, cPicture1: C28,
      cText1: "‘The person of the forest.’",
      content: "Anyone who enters the space is first greeted by an experiential walkway that creates a flow towards the ‘forest’ and becomes the trail to the reception and the great skylight. The facility carves itself as a space for experience, observation, and contemplation.",
      subContent1: "Orangutan, the brand new E-Sports training facility that blends together all the aspects of the online gaming world including living and relaxation. The interiors not only enhance the experience for the players, but also for the visitors and viewers that get to physically and virtually escape into this one-of-a-kind world.",
      subContent2: "The facility showcases its eclectic personality throughout the space. The dichotomy of natural elements and modern technology brings forth a unique blend of design sensibility that helps cater to both.",

      video: Video28,
      middleSectionPictures: [
        { id: 1, picture: Section82, alt: "slider-1",  },
        { id: 2, picture: Section83, alt: "slider-2",  },
        { id: 3, picture: Section84, alt: "slider-3",  }
      ],
      mainSliderPictures: [
        { id: 1, picture: Slider158, alt: "slider-1",  },
        { id: 2, picture: Slider159, alt: "slider-2",  },
        { id: 3, picture: Slider160, alt: "slider-3",  },
        { id: 4, picture: Slider161, alt: "slider-4",  },
        { id: 5, picture: Slider162, alt: "slider-5",  },
		{ id: 6, picture: Slider163, alt: "slider-3",  },
        { id: 7, picture: Slider164, alt: "slider-4",  },
        { id: 8, picture: Slider165, alt: "slider-5",  }
      ],
    }
  },
    
];
