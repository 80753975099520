import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import InnerBg from '../../assets/images/about.gif';
import AboutImg from '../../assets/images/AboutImage.png';
import AboutImg2 from '../../assets/video/about-video.mp4';
import aboutOne from '../../assets/images/aboutOne.png';
import aboutTwo from '../../assets/images/aboutTwo.png';
import WOW from "wowjs";
import { styles as useStyles } from './styles';

const About = () => {
  const classes = useStyles();
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);
  return (
    <div className={classes.main}>
      <Navbar />
      <a href='/' className="InnerBg">
        <img src={InnerBg} alt="" />
      </a>
      <div className="container pb-5 zIndex">
        <div className="row align-items-center">
          <div className="col-md-12 pb-5">
            <h1 className='heading headingAbout dec'>
              About
            </h1>
          </div>
          <div className='col-lg-5'>
            <p>
              AN EXTROVERT GROUP OF DESIGN ENTHUSIASTS THAT HAVE A GLOBAL SLANT, WE BELIEVE IN EXTENDING AND EXPERIENCING DESIGN AND CREATIVE EXPRESSION IN ALL AREAS, BE IT A HOME, RESTAURANT, FURNITURE PIECE, EVERYDAY PRODUCT, OR A LARGE COMMERCIAL SPACE OR BIENNALE.
            </p>
            <p className='SmallCaps'>
              With a scenographic and imaginative direction in spatial design, we have the expertise of creating swish residential, hospitality, and commercial architecture - interior layouts of any magnitude. Having an experience of almost a decade and a coherently collaborated team of adroit professionals, we have completed over 90 projects at Colab Company.
            </p>
            <p className='SmallCaps'>Our creative directions extend from designing one-of-a-kind hospitality interior experiences to sprawling residential spaces that have dynamism and a global outlook and are further expressed in curating bespoke product and furniture designs.</p>
            {/* <h2 className='heading'>
                10LAC <br />
                SQ. M. AREA
              </h2> */}
          </div>
          <div className='col-lg-7 mt-5 mt-lg-0'>
            <div className='AboutImg'>
              <img src={AboutImg} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className='aboutContent'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-9'>
              <div className='row'>
                <div className='col-md-6 pe-md-0'>
                  <div className='AboutOne'>
                    <img src={aboutOne} className="wow fadeInDown" data-wow-duration="2s" alt="AboutOne"
                      data-wow-delay="0s" />
                  </div>
                  <div className="d-none d-md-block">
                    <div className='AboutOnetext text-center text-md-start'>
                      Our creative founders, Hiren Ganatra and Saloni Ganatra have an eclectic and intuitive design approach. Balancing dynamism and solidity, wonder, and comfort, they have established a team of great expertise and realised projects of all scales.
                    </div>
                    <p className='text-center text-md-start'><a href='/contact' className='ConactLink'>CONTACT</a></p>
                  </div>
                </div>
                <div className='col-md-6 AboutRights ps-md-0'>
                  <div className='AboutTwo'>
                    <img src={aboutTwo} className="mb-4 mb-md-0 wow fadeInUp" data-wow-duration="2s" alt="AboutTwo"
                      data-wow-delay="0s" />
                    <p>Every space should be playful and have a whim. </p>
                  </div>

                  <div className="d-md-none">
                    <div className='AboutOnetext text-center text-md-start'>
                      Our creative founders, Hiren Ganatra and Saloni Ganatra have an eclectic and intuitive design approach. Balancing dynamism and solidity, wonder, and comfort, they have established a team of great expertise and realised projects of all scales.
                    </div>
                    <p className='text-center text-md-start'><a href='/contact' className='ConactLink'>CONTACT</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container ms-lg-0 ps-lg-0 zIndex">
        <div className='row align-items-center ms-lg-0 aboutVideoDiv'>
          <div className='col-lg-6 mb-5 ps-lg-0 mb-lg-0'>
            <div className='AboutVideos'>
              <video
                playsInline
                loop
                autoPlay
                muted
                src={AboutImg2}
              />
            </div>
          </div>
          <div className='col-lg-6 pb-5 pb-lg-0'>
			<h2 className='heading'>
              DESIGN FLAMBOYANCE
            </h2>
			&nbsp;&nbsp;
            <p>
              ‘Representative for adaptive and reciprocative’- We believe in creating spaces that echo each client’s personality with our unique creative and aesthetic mastery. While being completely in sync with the brief, we love expressing the idea of ‘aesthetic explosion’ in all our projects, irrespective of the genre and scale.
            </p>
            
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
};

export default About;
