import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({

    root: {
        backgroundColor: '#0cafff',
        zIndex: '1',
        position: 'relative',
    }

}));

