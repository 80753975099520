import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

export default class CenterMode extends Component {
  
  render() {
    const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      fade: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      pauseOnHover: false,
      autoplaySpeed: 6000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
      ],
    };

    return (
      <div className="DetailSlider">
        <Slider {...settings}>
          {this.props.pictures && this.props.pictures.length>0 && this.props.pictures.map(pic=>{
            return (
            <div className="feat-img" key={`Slider-Image-${pic.id}`}>
              <img src={pic.picture} alt={`slider-${pic.id}`} />
            </div>)
          })}
        </Slider>
      </div>
    );
  }
}
