import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeInteriors from '../../components/Home/HomeInteriors/HomeInteriors';
import HomeArchitecture from '../../components/Home/HomeArchitecture/HomeArchitecture';
import HomeArt from '../../components/Home/HomeArt/HomeArt';
import HomeFurniture from '../../components/Home/HomeFurniture/HomeFurniture';
import HomeProduct from '../../components/Home/HomeProduct/HomeProduct';
import HomeLogos from '../../components/Home/HomeLogos/HomeLogos';
import Navbar from '../../components/Navbar/Navbar';
//import InnerBg from '../../assets/images/COALB.gif';
import Icon1 from "../../assets/images/Icon-1.png";
import Icon1Hover from "../../assets/images/Icon-1-hover.png";
import Icon2 from "../../assets/images/Icon-2.png";
import Icon2Hover from "../../assets/images/Icon-2-hover.png";
import Icon3 from "../../assets/images/Icon-3.png";
import Icon3Hover from "../../assets/images/Icon-3-hover.png";
import Icon4 from "../../assets/images/Icon-4.png";
import Icon4Hover from "../../assets/images/Icon-4-hover.png";
import Icon5 from "../../assets/images/Icon-5.png";
import Icon5Hover from "../../assets/images/Icon-5-hover.png";
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage';
import WOW from "wowjs";

const Work = () => {
  const [pageSelected, setPageSelected] = useState(0);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

    let callback = (entries, observer) => {
      entries.forEach(entry => {
        if(entry.isIntersecting || entry.isVisible){
          
          if(entry.target.id === 'HomeInterior'){
            setPageSelected(0);
            return;
          }
          if(entry.target.id === 'HomeArchitechture'){
            setPageSelected(1);
            return;
          }
          if(entry.target.id === 'HomeExhibition'){
            setPageSelected(2);
            return;
          } 
          if(entry.target.id === 'HomeFurniture'){
            setPageSelected(3);
            return;
          } 
          if(entry.target.id === 'HomeProduct'){
            setPageSelected(4);
            return;
          }
          if(entry.target.id === 'HomeLogos'){
            setPageSelected(5);
            return;
          }
        }
      })
    }

    let observer = new IntersectionObserver(callback, {
      threshold: [0.5]
    });
    
    
    ['HomeInterior', 'HomeArchitechture', 'HomeExhibition', 'HomeFurniture', 'HomeProduct', 'HomeLogos'].forEach(d => {
      const div = document.getElementById(d);
      if (div) observer.observe(div);
    })
      
  }, []);


  return (
    <div className="mainHome work">
      <Navbar />
      <div className="App">
        {pageSelected !== 6  &&
        <header className="nav">
          <nav className="nav__container__actions d-md-block">
            <ul>
              <li>
                {pageSelected === 0 ?
                  <img className='ActiveIcon' alt="HomeInteriorIconHover" src={Icon1Hover} />
                  : <img className='SideIcon' alt="HomeInteriorIcon" src={Icon1} />
                }
              </li>
              <li>
                {pageSelected === 1 ?
                  <img className='ActiveIcon' alt="HomeArcitechIconHover" src={Icon2Hover} />
                  : <img className='SideIcon' alt="HomeArcitechIcon" src={Icon2} />
                }
              </li>
              <li>
                {pageSelected === 2 ?
                  <img className='ActiveIcon' alt="HomeExhibitionIconHover" src={Icon3Hover} />
                  : <img className='SideIcon' alt="HomeExhibitionIcon" src={Icon3} />
                }
              </li>
              <li>
                {pageSelected === 3 ?
                  <img className='ActiveIcon' alt="HomeFurnitureHover" src={Icon4Hover} />
                  : <img className='SideIcon' alt="HomeFurnitureIcon" src={Icon4} />
                }
              </li>
              <li>
                {pageSelected === 4 ?
                  <img className='ActiveIcon' alt="HomeProductIconHover" src={Icon5Hover} />
                  : <img className='SideIcon' alt="HomeProductIcon" src={Icon5} />
                }
              </li>
            </ul>
          </nav>
        </header>}
      </div>

      <div>
       <Fullpage>    
        <FullPageSections>
          <FullpageSection id="intSection" style={{
            height: '100vh'
          }}>
            <HomeInteriors />
          </FullpageSection>
          <FullpageSection style={{
            height: '100vh'
          }} >
            <HomeArchitecture />
          </FullpageSection>
          <FullpageSection style={{
            height: '100vh'
          }}>
            <HomeArt />
          </FullpageSection>
          <FullpageSection style={{
            height: '100vh'
          }}>
            <HomeFurniture />
          </FullpageSection>
          <FullpageSection style={{
            height: '100vh'
          }}>
            <HomeProduct />
          </FullpageSection>
          <FullpageSection style={{
            height: '100vh'
          }}>
            <HomeLogos />
          </FullpageSection>
        </FullPageSections>
        </Fullpage>
      </div>
    </div>
  );
};

export default Work;
