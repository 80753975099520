import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { styles as useStyles } from './styles';
import Footer from '../../Footer/Footer';

const HomeLogos = () => {
  const classes = useStyles();
  return (
    <>
      <div className='bgWhite' id="HomeLogos">
        <section className="whyCollab">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center mb-3">
                <h1> WHY COLAB COMPANY?</h1>
              </div>

              <div className="col-md-4 text-left">
                <p>Colab is an eclectic and fanatical interior design and architecture studio. With an inlay passion for experimenting and creating spaces that are idiosyncratic in form, function, and experience, we rather say this belief is a dogmatic notion for us as a team. Our aesthetic approach is characterised by the belief in creating designs that meld emotions, comfort, luxury, and spectacle. </p>
              </div>
              <div className="col-md-4 text-left">
                <p>Our founders, Hiren Ganatra and Saloni Ganatra both believe in design aberration. Their ideation and characterisation splinter the cliche norms of interior- architecture. They ideate and fabricate projects with their own modus operandi that have spectacle, uniqueness, and extravagance.</p>
              </div>
            </div>
          </div>
        </section>
        <div className={classes.root}>
          <div className='container-fluid px-0'>
            <div className='HomeLogosRepeat'></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default HomeLogos;
