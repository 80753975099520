import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import WOW from "wowjs";


import HomeInterior from "../../../assets/images/HomeInterior.png";
import HomeInteriorLogo from "../../../assets/images/HomeInteriorLogo.png";
import HomeInteriorLogoHover from "../../../assets/images/HomeInteriorLogoHover.png";


export default function App() {


  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  return (
   
      <section id="HomeInterior" className="HomeHovers py-5 full-page">
        <div className="container" id="xsada">
          <div className="row flex-direction-mobile">
            <div className="col-md-5 position-relative">
              <div className="ContentSection">
                <a href="/interiors">
                  <h6>EXPLORE MORE</h6>
                  <h2 className="mb-3">
                    interiors
                  </h2>
                  <p>
                    Creating unfathomable and charismatic spaces with a flair of evocative and global inclinations.
                  </p>
                </a>
              </div>
            </div>
            <div className="col-md-7">
              <div className="HomeContentImg">
                <img alt="HomeInteriorLogoHover" src={HomeInteriorLogoHover} className="homelogoHover " />
                <img alt="HomeInteriorLogo" src={HomeInteriorLogo} className="homelogo " />
                <img alt="HomeInterior" src={HomeInterior} className="homeImg" />
              </div>
            </div>
          </div>
        </div>
      </section>

    
  );
}
