import { makeStyles } from '@material-ui/core/styles';
import InnerBg from '../../assets/images/innerbg.svg';
export const styles = makeStyles((theme) => ({
  main: {
    overflow: 'hidden',
    paddingTop: '100px',
    position: 'relative',
    backgroundImage: InnerBg,
  },
  error: {
    color: 'red',
    marginBottom: '0'
  }
}));
