import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import InnerBg from '../../assets/images/press.gif';
import PressOne from '../../assets/images/press/press-1.jpg';
import PressTwo from '../../assets/images/press/press-2.jpg';
import PressThree from '../../assets/images/press/press-3.gif';
import PressFour from '../../assets/images/press/press-4.jpg';
import PressFive from '../../assets/images/press/press-5.jpg';
import PressSix from '../../assets/images/press/press05.mov';
import { styles as useStyles } from './styles';

const Press = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Navbar />
      <a href='/' className="InnerBg">
        <img src={InnerBg} alt="" />
      </a>
      <div className="container pb-5 zIndex">
        <div className="row align-items-center">
          <div className="col-md-12 pb-5">
            <h1 className='heading headingAbout '>
              Press
            </h1>
          </div>
        </div>

        <div className='row justify-content-center'>
          <div className='col-md-9'>
            <div className="row align-items-start PressPageMain">
              <div className='col-lg-6 mt-4 mt-lg-5'>
                <a href='https://www.elle.com/nl/interieur/binnenkijkers/a35921404/binnenkijker-casa-neue-scarlet-splendor-mumbai-huis/' target="_blank" rel="noreferrer">
                  <div className='PressContainer' >
                    <div className='PressImg'>
                      <img src={PressOne} alt="PressOne" className="PressOneImg" />
                    </div>
                    <div className='PressContents'>
                      <h6>ELLE NETHERLANDS</h6>
                      <p>Binnenkijker: in dit bijzondere huis in Mumbai wordt maximalisme gevierd</p>
                      <h6>APRIL, 2021</h6>
                    </div>
                  </div>
                </a>
              </div>
              <div className='col-lg-6 mt-4 mt-lg-5'>
                <a href='https://dailydesignews.com/casa-neue-an-eclectic-project-in-india/' target="_blank" rel="noreferrer">
                  <div className='PressContainer'>
                    <div className='PressImg'>
                      <img src={PressTwo} alt="PressTwo" className="PressOneImg" />
                    </div>
                    <div className='PressContents'>
                      <h6>DESIGN NEWS</h6>
                      <p>CASA NEUE- An eclectic project in India</p>
                      <h6>APRIL, 2021</h6>
                    </div>
                  </div>
                </a>
              </div>
              <div className='col-lg-6 mt-4 mt-lg-5'>
                <div className='PressContainer'>
                  <div className='PressImg'>
                    <img src={PressThree} alt="PressThree" />
                  </div>
                </div>
              </div>
              <div className='col-lg-6 mt-4 mt-lg-5'>
                <a href='https://www.livingetc.com/features/glamorous-home-mumbai-imaginative-furniture' target="_blank" rel="noreferrer">
                  <div className='PressContainer'>
                    <div className='PressImg'>
                      <img src={PressFour} alt="PressFour" className="PressOneImg" />
                    </div>
                    <div className='PressContents'>
                      <h6>LIVINGETC UK</h6>
                      <p>Imaginative furniture pieces inject this ultra-glam Mumbai mansion with whimsy</p>
                      <h6>APRIL, 2021</h6>
                    </div>
                  </div>
                </a>
              </div>
              <div className='col-lg-6 mt-4 mt-lg-5'>
                <a href='https://elledecor.in/article/saloni-and-hiren-ganatra-of-colabcompany-punctuate-this-expansive-apartment-with-m
aximalism-and-eclectic-decor/' target="_blank" rel="noreferrer">
                  <div className='PressContainer'>
                    <div className='PressImg'>
                      <img src={PressFive} alt="PressFive" className="PressOneImg" />
                    </div>
                    <div className='PressContents'>
                      <h6>ELLE DECOR INDIA</h6>
                      <p>Saloni and Hiren Ganatra of ColabCompany punctuate this expansive apartment with maximalism and eclectic decor</p>
                      <h6>JUNE, 2021</h6>
                    </div>
                  </div>
                </a>
              </div>
              <div className='col-lg-6 mt-4 mt-lg-5'>
                <div className='PressContainer'>
                  <div className='AboutVideos PressVideos'>
                    <video
                      playsInline
                      loop
                      autoPlay
                      muted
                      src={PressSix}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
};

export default Press;
