import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  main: {
    overflow: 'hidden',
    paddingTop: '75px',
    position: 'relative',
    backgroundImage: "InnerBg",
  },
}));
