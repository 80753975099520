import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import WOW from "wowjs";


import HomeExhibition from "../../../assets/images/Homeexhibition.png";
import HomeExhibitionLogo from "../../../assets/images/HomeexhibitionLogo.png";
import HomeExhibitionLogoHover from "../../../assets/images/HomeexhibitionLogoHover.png";

export default function App() {


  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  return (
      <section id="HomeExhibition" className="HomeHovers py-5">
        <div className="container">
          <div className="row flex-direction-mobile">
            <div className="col-md-5 position-relative">
              <div className="ContentSection">
                <a href="/exhibitions">
                  <h6>EXPLORE MORE</h6>
                  <h2 className="mb-3">
                    ART &
                    EXHIBITIONS
                  </h2>
                  <p>
                    Extending our aesthetic knowledge beyond interior and architecture, we curate exhibitions and biennales that are aesthetically, sensorially, and emotionally profound experiences.
                  </p>
                </a>
              </div>
            </div>
            <div className="col-md-7">
              <div className="HomeContentImg">
                <img alt="HomeExhibitionLogo" src={HomeExhibitionLogoHover} className="homelogoHover " />
                <img alt="HomeExhibition" src={HomeExhibitionLogo} className="homelogo " />
                <img alt="HomeExhibitionIcon" src={HomeExhibition} className="homeImg" />
              </div>
            </div>
          </div>
        </div>
      </section>


  );
}
