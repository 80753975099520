import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Work from './pages/Work/Work';
import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import Press from './pages/Press/Press';
import Interiors from './pages/Interiors/Interiors';
import InteriorsDetail from './pages/InteriorsDetail/InteriorsDetail';
import Architectures from './pages/Architectures/Architectures';
import ArchitectureDetail from './pages/ArchitectureDetail/ArchitectureDetail';
import Products from './pages/Products/Products';
import ProductDetail from './pages/ProductsDetail/ProductsDetail';
import Exhibitions from './pages/Exhibitions/Exhibitions';
import ExhibitionsDetail from './pages/ExhibitionsDetail/ExhibitionsDetail';
import Furnitures from './pages/Furnitures/Furnitures';

const MainRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
		 <Route exact path="/work">
          <Work />
        </Route>
        <Route exact path="/contact/">
          <Contact />
        </Route>
        <Route exact path="/about/">
          <About />
        </Route>
        <Route exact path="/press/">
          <Press />
        </Route>
        <Route exact path="/interiors/">
          <Interiors />
        </Route>
        <Route path="/interiors-detail/:id">
          <InteriorsDetail />
        </Route>
		<Route exact path="/architectures/">
          <Architectures />
        </Route>
        <Route path="/architectures-detail/:id">
          <ArchitectureDetail />
        </Route>
		<Route exact path="/products/">
          <Products />
        </Route>
        <Route path="/products-detail/:id">
          <ProductDetail />
        </Route>
		<Route exact path="/exhibitions/">
          <Exhibitions />
        </Route>
        <Route path="/exhibitions-detail/:id">
          <ExhibitionsDetail />
        </Route>
		<Route exact path="/furnitures/">
          <Furnitures />
        </Route>
      </Switch>
    </Router>
  );
};
export default MainRouter;
