import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import WOW from "wowjs";
import HomeProduct from "../../../assets/images/Homeproduct.png";
import HomeProductLogo from "../../../assets/images/HomeproductLogo.png";
import HomeProductLogoHover from "../../../assets/images/HomeproductLogoHover.png";

export default function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
      <section id="HomeProduct" className="py-5 HomeHovers">
        <div className="container py-5">
          <div className="row flex-direction-mobile">
            <div className="col-md-5 position-relative">
              <div className="ContentSection">
                <a href="/products">
                  <h6>EXPLORE MORE</h6>
                  <h2 className="mb-3">
                    PRODUCTS
                  </h2>
                  <p>
                    Bespoke merchandise that has an edge of whim- exploring vivid ideas and materials, to elevate the human experience with product designs that have an agape splendor.
                  </p>
                </a>
              </div>
            </div>
            <div className="col-md-7">
              <div className="HomeContentImg">
                <img alt="HomeLogo" src={HomeProductLogoHover} className="homelogoHover " />
                <img alt="HomeProductLogo" src={HomeProductLogo} className="homelogo " />
                <img alt="HomeProduct" src={HomeProduct} className="homeImg" />
              </div>
            </div>
          </div>
        </div>
      </section>


  );
}
