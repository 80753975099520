import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import InnerBg from '../../assets/images/interior.gif';
import { interiors, INTERIOR_TYPES } from "../../utils/data";
import { styles as useStyles } from './styles';

const Interiors = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Navbar />
      <a href='/' className="InnerBg">
        <img src={InnerBg} alt="" />
      </a>
      <div className="container pb-4 zIndex">
        <div className="row align-items-center">
          <div className="col-md-12 pb-0 pb-md-5">
            <h1 className='heading headingAbout dec'> Interiors </h1>
          </div>
        </div>
      </div>
      <div className="container pb-5 zIndex">
        <ul className='InteriorUls'>
          {INTERIOR_TYPES.map(type => (
            <li key={type.href}>
              <a href={`#${type.href}`} >{type.name}</a>
            </li>
          ))}
        </ul>
        {INTERIOR_TYPES.map(type => {
          return (
            <div className='InterSection pt-2 pt-md-5 mb-5' id={type.href} key={`key-${type.href}`}>
              <div className='row justify-content-between align-items-center InterHead'>
                <div className='col-md col-6'>
                  <div className='row'>
                    <div className='col-md-auto pb-0 pb-md-0'>
                      <h2>{type.name}</h2>
                    </div>
                    <div className='col-md-7 d-none d-md-inline-flex'><p>{type.text}</p></div>
                  </div>
                </div>
                <div className='col-md-4 col-6 text-end mt-0 mt-md-0'>
                  <div className='ProjectCount'>[{interiors.filter(item => item.type === type.name).length || 0} PROJECTS ]</div>
                </div>
              </div>
              <div className='InteriorImagesGroup mt-3'>
                {interiors.map((interior) => (
                  interior.type === type.name &&
                  <div className='interImgs' id={interior.id} total={32} key={`interior-${interior.id}`}>
                    <a href={interior.url}>
                      <div className='InerImg'>
                        <img src={interior.picture} alt="interior" />
						 {interior.text && <div className='OverLay' dangerouslySetInnerHTML={{ __html: `${interior.text}` }} />}
                        
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </div>
      <Footer />
    </div>
  );
};

export default Interiors;
