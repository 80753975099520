import React from 'react';
import { Grid } from '@material-ui/core';
import { styles as useStyles } from './styles';
import facebook from './../../assets/images/facebook.svg';
import facebook2 from './../../assets/images/facebook2.svg';
import instagram from './../../assets/images/instagram.svg';
import instagram2 from './../../assets/images/instagram2.svg';
import linkedin from './../../assets/images/linkedin.svg';
import linkedin2 from './../../assets/images/linkedin2.svg';
import youtube from './../../assets/images/youtube.svg';
import youtube2 from './../../assets/images/youtube2.svg';
import footerLogo from './../../assets/images/cclogo.svg';

import Follow from './../../assets/images/follow.svg';
const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <div className='container footerDiv'>
        <Grid container>
          <Grid item md={7} xs={12}>
            <div className={classes.logoSection}>
              <a href='/'>
              <img src={footerLogo} alt="footerLogo" className={classes.footerLogo} />
           </a>
            </div>
          </Grid>
          <Grid item md={5} xs={12} className={classes.subtextGrid}>
            <div className={classes.subText}>
              <ul className={classes.inforList}>
			          <li>
                  <a href="/" rel="noreferrer" className={classes.subText}>Home</a>
                </li>
                <li>
                  <a href="/work" rel="noreferrer" className={classes.subText}>Work</a>
                </li>
                <li>
                  <a href="/about/" rel="noreferrer" className={classes.subText}>About</a>
                </li>
                <li>
                  <a href="/press/" rel="noreferrer" className={classes.subText}>Press</a>
                </li>

                <li>
                  <a href="/contact/" rel="noreferrer" className={classes.subText}>Contact</a>
                </li>
              </ul>
            </div>
            <div className={classes.Follow}>
              <img src={Follow} alt="" className={classes.follow} />
              <ul className={classes.SocialUls}>
                <li>
                  <a href='https://www.facebook.com/ColabCompany/' rel="noreferrer"  target='_blank'>
                    <img src={facebook} alt="facebook" />
                    <img src={facebook2} alt="facebook" />
                  </a>
                </li>
                <li>
                  <a href='https://www.instagram.com/colab.co/' rel="noreferrer"  target='_blank'>
                    <img src={instagram} alt="instagram" />
                    <img src={instagram2} alt="instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/colab-company/" rel="noreferrer"  target="_blank">
                    <img src={linkedin} alt="linkedin" />
                    <img src={linkedin2} alt="linkedin" />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@ColabCompany/featured" rel="noreferrer"  target='_blank'>
                    <img src={youtube} alt="youtube" />
                    <img src={youtube2} alt="youtube" />
                  </a>
                </li>
              </ul>
            </div>
            <p className={classes.CopyRight}>© 2023 by ColabCompany. all rights reserved.</p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Footer;
