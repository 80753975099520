import React, { useEffect, useState } from 'react';
import {
  withWidth
} from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import DetailASlider from '../../components/InteriorDetail/DetailSlider/DetailSlider';
import InnerBg from '../../assets/images/architecture-detail.gif';
import Watch from '../../assets/images/interior-detail/watch.png';
import { useParams } from 'react-router-dom';
import { architectures } from "../../utils/data";
import { styles as useStyles } from './styles';
import WOW from "wowjs";
const ArchitecturesDetail = () => {
  const classes = useStyles();
  const params = useParams();
  const [detail, setDetail] = useState({});

  useEffect(() => {
    const value = architectures.find((int) => int.id === Number(params.id))?.detail;
    setDetail(value);
  }, [params.id]);
   useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <div className={classes.main}>
      <Navbar />

      <a href='/' className="InnerBg">
        <img src={InnerBg} alt="" />
      </a>

      <div className="container pb-4 zIndex">
        <div className="row align-items-center">
          <div className="col-md-12 pb-5">
            <h1 className='heading headingAbout dec'>
              architectures
            </h1>
          </div>
        </div>
      </div>
      {detail &&
        <>
          <div className="container pb-2 zIndex">
            <h2 className='mb-0 Heading2'>{detail.mainHeading}</h2>
          </div>
          <DetailASlider pictures={detail.mainSliderPictures} />
          <div className="container pb-md-5 pb-4 mt-4 mt-md-5 zIndex">
            <h2 className='heading text-center'>{detail.cHeading}</h2>

            {detail.middleSectionPictures && detail.middleSectionPictures.length > 0 &&
              <div className='row mt-3'>
                {detail.middleSectionPictures.map(image => (
                  <div className='col-md-4 px-2 mb-3' key={`mid-section-${image.id}`}>
                    <div className='DetailThreeImg'><img src={image.picture} alt="" /></div>
                  </div>
                ))}
              </div>
            }

            <div className='row justify-content-center mt-4 mt-md-5'>
              {detail.subContent1 &&
                <div className='col-md-5 px-md-5'>
                  <div dangerouslySetInnerHTML={{ __html: `${detail.subContent1}` }} />
                </div>
              }
              {detail.subContent2 &&
                <div className='col-md-5 px-md-5'>
                  <div dangerouslySetInnerHTML={{ __html: `${detail.subContent2}` }} />
                </div>
              }
            </div>

            <div className='row justify-content-center mt-4 mt-md-5'>
              <div className='col-md-11'>
                <div className='row justify-content-center'>
                  {detail.cPicture1 &&
                    <div className='col-md-5'>
					<img src={detail.cPicture1} alt="interior-detail-section" className="wow fadeInDown architecture" data-wow-duration="2s"
                        data-wow-delay="0s" />
                    </div>
                  }
                  <div className='col-md-5 pt-5 pt-md-5'>
                    <h2 className='Heading2 mt-md-4'>{detail.cText1}</h2>
                    {detail.content && <div className='mt-4 mt-md-5' dangerouslySetInnerHTML={{ __html: `${detail.content}` }} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {detail.video &&
            <div className='DetailVideo mt-4 mt-md-5'>
              <img src={Watch} alt="" className='BtnWatch' />
              <video
                playsInline
                loop
                autoPlay
                muted
                src={detail.video}
              />
            </div>
          }
        </>
      }
      <Footer />
    </div>
  );
};

export default withWidth()(ArchitecturesDetail);
