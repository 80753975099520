import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    width: 'auto',
  },
  icon: {
    fontSize: 30
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#FFF',
    zIndex: 9,
    fontSize: 20
  },
  toolbar: {
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
    zIndex: 20
  },
  title: {
    flexGrow: 1,
    paddingTop: 2,
    fontSize: 38,
  },
  menu: {
    paddingTop: theme.spacing(1),
    height: '100%',
    width: 0,
    position: 'fixed',
    zIndex: '1',
    top: '0',
    right: '0',
    overflowX: 'hidden',
    transition: '0.5s',
    // paddingTop: '60px',
    color: '#FFF'
  },
  mainAppbar: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    position: 'absolute',
    width: 'auto',
  },
  menuOption: {
    fontSize: 14,
    marginRight: 60,
    textTransform: 'capitalize',
    borderRadius: 30,
    width: 120,
    // '&:hover': {
    //   backgroundColor: theme.palette.secondary.main,
    // },
  },
  innerBg: {
    position: 'absolute',
    top: 10,
    left: 10,
    width: 235,
    zIndex: 0
  }
}));
