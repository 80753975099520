import React from 'react';
import MainRouter from './router';
const MainApp = () => {
  return (
    <>
      <MainRouter />
    </>
  );
};

export default MainApp;
