import React, { useEffect, useState } from 'react';
import {
  withWidth
} from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import DetailSlider from '../../components/InteriorDetail/DetailSlider/DetailSlider';
import InnerBg from '../../assets/images/interior-detail.gif';
import { useParams } from 'react-router-dom';
import { products } from "../../utils/data";
import { styles as useStyles } from './styles';
import WOW from "wowjs";

const ProductsDetail = () => {
  const classes = useStyles();
  const params = useParams();
  const [detail, setDetail] = useState({});
  const total=32;
  useEffect(() => {
    const value = products.find((int) => int.id === Number(params.id))?.detail;
    setDetail(value);
  }, [params.id]);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <div className={classes.main}>
      <Navbar />

      <a href='/' className="InnerBg">
        <img src={InnerBg} alt="" />
      </a>

      <div className="container pb-4 zIndex">
        <div className="row align-items-center">
          <div className="col-md-12 pb-5">
            <h1 className='heading headingAbout dec'>
              Products
            </h1>
          </div>
        </div>
      </div>
      {detail &&
        <>
          <div className="container pb-3 zIndex">
            <h2 className='mb-0 Heading2'>{detail.mainHeading}</h2>
          </div>
          <DetailSlider pictures={detail.mainSliderPictures} />
          <div className="container pb-md-5 pb-4 mt-4 mt-md-5 zIndex productsDetail">
            {detail.content && <div className='mt-4 mt-md-5' dangerouslySetInnerHTML={{ __html: `${detail.content}` }} />}

            {detail.middleSectionPictures && detail.middleSectionPictures.length > 0 &&
              <div className='row mt-3'>
                {detail.middleSectionPictures.map(image => (
                  <div className='col-md-4 px-2 mb-3' key={`mid-section-${image.id}`}>
                    <div className='DetailThreeImg'><img src={image.picture} alt="" /></div>
                  </div>
                ))}
              </div>
            }

            

            
          </div>
         
        </>
      }

      <div className="container">
        <div className="DetailArrow">
			<a href={`/products-detail/${detail.id===1?1:detail.id-1}`} className="arrows leftarrow">
            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 61.7 146.53"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect className="cls-1" x="27.78" y="-9.41" width="4.24" height="95" rx="2.12" ry="2.12" transform="translate(29.42 -10.33) rotate(37.57)" /><rect className="cls-1" x="28.73" y="61.79" width="4.24" height="95" rx="2.12" ry="2.12" transform="translate(123.92 174.35) rotate(140.72)" /></g></g></svg> <span> Previous Product</span>
          </a>
          <a href={`/products-detail/${detail.id===total?1:detail.id+1}`} className="arrows leftarrow">
            <span> Next Product</span>
            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 61.7 146.53"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect className="cls-1" x="29.68" y="-9.41" width="4.24" height="95" rx="2.12" ry="2.12" transform="translate(80.24 48.89) rotate(142.43)" /><rect className="cls-1" x="28.73" y="61.79" width="4.24" height="95" rx="2.12" ry="2.12" transform="translate(76.16 5.16) rotate(39.28)" /></g></g></svg>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withWidth()(ProductsDetail);
