import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import InnerBg from '../../assets/images/architecture.gif';
import { architectures, ARCHITECTURE_TYPES } from "../../utils/data";
import { styles as useStyles } from './styles';

const Architectures = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Navbar />
      <a href='/' className="InnerBg">
        <img src={InnerBg} alt="" />
      </a>
      <div className="container pb-4 zIndex">
        <div className="row align-items-center">
          <div className="col-md-12 pb-5">
            <h1 className='heading headingAbout dec'> Architectures </h1>
          </div>
        </div>
      </div>
      <div className="container pb-5 zIndex">

        {ARCHITECTURE_TYPES.map(type => {
          return (
            <div className='InterSection pt-5 mb-5' id={type.href} key={`key-${type.href}`}>
              <div className='row justify-content-between align-items-center InterHead'>
                <div className='col-md'>
                  <div className='row'>
                    <div className='col-md-auto pb-3 pb-md-0'>
                      <h2>{type.name}</h2>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 text-md-end mt-3 mt-md-0'>
                  <div className='ProjectCount'>[{architectures.filter(item => item.type === type.name).length || 0} PROJECT ]</div>
                </div>
              </div>
              <div className='InteriorImagesGroup mt-3'>
                {architectures.map((architecture) => (
                  architecture.type === type.name &&
                  <div className='interImgs' key={`architecture-${architecture.id}`}>
                    <a href={architecture.url}>
                      <div className='InerImg'>
                        <img src={architecture.picture} alt="architecture" />
                        <div className='OverLay'>{architecture.text}</div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </div>
      <Footer />
    </div>
  );
};

export default Architectures;
