import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Slider1 from "../../../assets/images/homeslider/slide2.mp4";
import Slider2 from "../../../assets/images/homeslider/slide2.png";
import Slider3 from "../../../assets/images/homeslider/slide3.png";
import Slider4 from "../../../assets/images/homeslider/slide4.png";
import Slider5 from "../../../assets/images/homeslider/slide5.png";
import Slider6 from "../../../assets/images/homeslider/CASANEUE.jpg";
import MobileSlider1 from "../../../assets/images/homeslider/mobileslide02.png";
import MobileSlider2 from "../../../assets/images/homeslider/mobileslide03.png";
import MobileSlider3 from "../../../assets/images/homeslider/mobileslide04.png";
import MobileSlider4 from "../../../assets/images/homeslider/mobileslide05.png";
import MobileSlider5 from "../../../assets/images/homeslider/mobileslide06.png";
import MOBILEVIDEO from "../../../assets/images/homeslider/mobileslide01.mov";
import InnerBg from '../../../assets/images/COALB.gif';

export default class CenterMode extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      fade: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      pauseOnHover: false,
      autoplaySpeed: 6000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
      ],
    };

    return (
      <section id="HomeSlider" className="sec">
      
      <div className="MainSlider">
      <a href='/' className="InnerBg">
        <img src={InnerBg} alt="" />
      </a>
        <Slider {...settings}>
          <div className="feat-img">
		  <video
              playsInline
              loop
              autoPlay
              muted
              src={Slider1}
			        className="d-none d-md-block"
            /><div className="Caption">
              <h2> COLAB COMPANY</h2>
            </div>
			      <video
              playsInline
              loop
              autoPlay
              muted
              src={MOBILEVIDEO}
			        className="d-md-none"
            />
          </div>
		   <div className="feat-img">
           <img src={Slider6} alt="" className="d-none d-md-block" />
		    <img src={MobileSlider1} alt="" className="d-md-none" />
            <div className="Caption">
              <h2><a href="/interiors-detail/32"> CASA NEUE</a></h2>
            </div>
          </div>
          <div className="feat-img">
           <img src={Slider2} alt="" className="d-none d-md-block" />
		    <img src={MobileSlider2} alt="" className="d-md-none" />
            <div className="Caption">
              <h2> <a href="/interiors-detail/34">ORANGATUN ESPORTS</a></h2>
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider3} alt="" className="d-none d-md-block" />
            <img src={MobileSlider3} alt="" className="d-md-none" />
            <div className="Caption">
              <h2> <a href="/architectures-detail/1">KUNENAMA MODERN</a></h2>
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider4} alt="" className="d-none d-md-block" />
            <img src={MobileSlider4} alt="" className="d-md-none" />
            <div className="Caption">
              <h2> <a href="/interiors-detail/27">DIGIENT TECHNOLOGIES</a></h2>
            </div>
          </div>
          <div className="feat-img">
            <img src={Slider5} alt="" className="d-none d-md-block" />
            <img src={MobileSlider5} alt="" className="d-md-none" />
            <div className="Caption">
              <h2> <a href="/interiors-detail/1">SHALOM</a></h2>
            </div>
          </div>
          


        </Slider>
      </div>
      </section>
    );
  }
}
